import { Injectable } from '@angular/core';
import { View } from '../../shared/_models/views';
import { environment } from 'environments/environment';

import { UserLoginService } from '../../shared/_services/user-login.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ViewsService {
    public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    public apiUrl = `${environment.lookupsApi}`; // URL to web api

    constructor(
        public http: HttpClient,
        public userLoginService: UserLoginService,
    ) {
        userLoginService.isAuthenticated(this);
    }

    isLoggedIn(token: any, isLoggedIn: boolean) {
        if (isLoggedIn && token) {
            this.headers.append('authorization', token);
        }
    }

    userName = this.getCurrentUser() ? this.getCurrentUser().AD_username : '';

    getViews(view): Promise<View[]> {
        return this.http
            .get(`${this.apiUrl}/api/views?list=${view}&user=${this.userName}`, {
                headers: this.headers,
            })
            .toPromise()
            .then((response) => (<any>response).json() as View[])
            .catch(this.handleError);
    }

    create(view: View): Promise<View> {
        return this.http
            .post(`${this.apiUrl}/api/views`, JSON.stringify({ view }), { headers: this.headers })
            .toPromise()
            .then((res) => (<any>res).json().data as View)
            .catch(this.handleError);
    }

    updateViews(body: any) {
        return this.http
            .put(`${this.apiUrl}/api/views`, JSON.stringify(body), { headers: this.headers })
            .toPromise()
            .then((res) => (<any>res).json())
            .catch(this.handleError);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
