<div class="page-top clearfix" baScrollPosition maxHeight="50" (scrollChange)="scrolledChanged($event)"
     [ngClass]="{scrolled: isScrolled}">
  <a routerLink="meetings" class="al-logo clearfix">
    <img  src="../../assets/images/un-logo.png">
    
    </a>
  <a href (click)="toggleMenu()" class="collapse-menu-link ion-navicon" id="toggleMenu"></a>
  <div class="search">
    <p>iGAEAD+ <a style="color: rgb(255, 51, 0);" href="javascript:void(0);">{{ environment.branch === 'PROD' ? '' : environment.branch }}</a></p>
  </div>

  <div class="user-profile clearfix">
    <div class="dropdown al-user-profile">
      <a class="profile-toggle-link dropdown-toggle" id="user-profile-dd" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="{{ ( 'profileicon' | baProfilePicture ) }}">
      </a>
      <ul class="dropdown-menu top-dropdown-menu profile-dropdown" aria-labelledby="user-profile-dd">
        <!-- <li class="dropdown-item"><a href><i class="fa fa-user"></i>Profile</a></li>
        <li class="dropdown-item"><a href><i class="fa fa-cog"></i>Settings</a></li> -->
        <li class="dropdown-item"><a (click)="signout();"><i  class="fa fa-power-off"></i>Sign out</a></li>
      </ul>
    </div>
  </div>
</div>
