import {
    IPST_TemplateMapping,
    TemplateParamsToDataPointsMappingEntity,
} from './IProcedureStepTypeLookup';

export interface IMeeting {
    _id: string;
    MT_jcmsMeetingId: string;
    MT_body: string;
    MT_type: string;
    MT_session: string;
    MT_name: string;
    MT_number: string;
    MT_commentary: string;
    MT_presidingOfficers: string;
    MT_dateTimeScheduleStart: string;
    MT_dateTimeScheduleEnd: string;
    MT_room: string;
    MT_dateTimeCreate: string;
    MT_Public: string;
    MT_Locked: string;
    MT_tags: string;
    MT_JCMSMeetingID: string;
    MT_isSubmit: boolean;
    valid: boolean;
    globalValid: boolean;
    MT_isDeleted: boolean;
    procedureStep?: IProcedureStepEntity[];
    MT_segment?: IMTSegmentEntity[];
    MT_files?: any;
    MT_datasource: string;
    flatObjects?: FlatObjectsEntity[];
    MT_SubmittedToJournal: string; // Yes = Hide submit button if it is Yes. Make it No when user un-publish meeting, so they can submit again.
    // calculated properties
    MT_bodyOriginal: string;
    MT_bodyFull: string; // it will have value of LKP_Value. ex: General Assembly Plenary
    MT_journalSummaryPrepared?: boolean; // true = MT_SubmittedToJournal !== 'Yes' && we have entry in journalsummaries table.
}

export interface IProcedureStepEntity {
    valid: boolean;
    _id?: string;
    seqNo: number;
    cType: string;
    PS_Version: string;
    PS_e_sponsorship: PSESponsorship;
    PS_selectSingleBody: string;
    PS_tags?: string[];
    PS_commentary: string;
    PS_meetingTime: string;
    PS_agendaStatus: string;
    PS_recordResolutionNumber: string;
    PS_recordDecisionNumber: string;
    PS_linkToEvote: string;
    PS_outcome: string;
    PS_abstention: string;
    PS_against: string;
    PS_InFavor: string;
    PS_voting: string;
    PS_objectOfAction: string;
    PS_selectAgendaFromWorkPackage?: string[];
    PS_ListOfSpeakers?: any;
    PS_linkListOfSpeakers: PSLinkListOfSpeakers;
    PS_selectSingleEntity: string;
    PS_selectDocumentFromWorkPackage?: string[];
    PS_selectWorkPackage: string;
    PS_type?: string;
    PS_title: string;

    // Non database field.
    jsState: any;
    PS_id: string;
}

export interface PSESponsorship {
    symbol: string;
    selectedSponsorType?: any;
}

export interface PSLinkListOfSpeakers {
    id: string;
    startSlot: string;
    endSlot: string;
}

export interface IMTSegmentEntity {
    MTS_procedurePatterns: string;
    ppDataModel?: any;
    valid: boolean;
    MTS_Public: string;
    seqNo: number;
    cType: string;
    MTS_agendaItem?: MTSAgendaItemEntity[];
    MTS_workPackage?: string[];
    MTS_segmentTitle: string;
    MTS_selectDocumentFromWorkPackage?: string[];
    // Non database field.
    jsState: any;
}

export interface MTSAgendaItemEntity {
    code: string;
    name: string;
}

export interface FlatObjectsEntity {
    MTS_procedurePatterns?: string;
    ppDataModel?: any;
    valid: boolean;
    MT_Public?: string;
    seqNo: number;
    cType: string;
    MTS_agendaItem?: MTSAgendaItemEntity[];
    MTS_workPackage?: string[];
    MTS_segmentTitle?: string;
    PS_Version?: string;
    PS_e_sponsorship?: PSESponsorship1;
    PS_selectSingleBody?: string;
    PS_tags?: string[];
    PS_commentary?: string;
    PS_meetingTime?: string;
    PS_agendaStatus?: string;
    PS_recordResolutionNumber?: string;
    PS_recordDecisionNumber?: string;
    PS_linkToEvote?: string;
    PS_outcome?: string;
    PS_abstention?: string;
    PS_against?: string;
    PS_InFavor?: string;
    PS_voting?: string;
    PS_objectOfAction?: string;
    PS_selectAgendaFromWorkPackage?: string[];
    PS_ListOfSpeakers?: any;
    PS_linkListOfSpeakers?: PSLinkListOfSpeakers1;
    PS_selectSingleEntity?: string;
    PS_selectDocumentFromWorkPackage?: string[];
    PS_selectWorkPackage?: string;
    PS_type?: string;
    PS_title?: string;
}

export interface PSESponsorship1 {
    symbol: string;
    selectedSponsorType?: any;
}

export interface PSLinkListOfSpeakers1 {
    id: string;
    startSlot: string;
    endSlot: string;
}

export interface ITemplateItem extends IPST_TemplateMapping {
    seqNo: number;
    savedSeqNo: string;
    type: EItemType;
    templateParamsToDataPointsMapping: TemplateParamsToDataPointsMappingEntity[];
    jcmsSummaryOutput?: string;
    jcmsSummaryOutputUI?: string; // it will display consolidated summary for sub templates also. Used to display in UI.
    parentItemId?: string; // it will be used by sub templates to refer to parent.

    tryToFindInDiffLookupCategory?: boolean;
}

export enum EItemType {
    SEGMENT = 'SEGMENT',
    PROCEDURE_STEP = 'PROCEDURE_STEP',
}

export interface ITemplateAndPSAndSegment
    extends ITemplateItem,
        IProcedureStepEntity,
        IMTSegmentEntity {
    isJCMSSummaryLineReady?: boolean;
    hideTemplateAndParameterSection?: boolean;
    loadAllTemplatesForSuperUser?: boolean; // if it is true, load all templates
    isNewPSTAddedInMeeting?: boolean; // true = if this PST is not in saved state
    isHavingSavedState?: boolean;
}
