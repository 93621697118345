import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { User } from '../_models/index';
import { environment } from './../../../environments/environment';
import { CognitoUtil } from './cognito.service';

import { UserLoginService } from './user-login.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiCachingService } from './api-caching.service';

@Injectable()
export class UserService {
    options: any;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    userInfo: any = {};
    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    private userModuleAccess: any;
    constructor(
        private http: HttpClient,
        public cognitoUtil: CognitoUtil,
        private httpClient: HttpClient,
        public userLoginService: UserLoginService,
        public apiCachingService: ApiCachingService,
    ) {
        this.options = <any>{};
        this.options.headers = new HttpHeaders();
    }

    getUser(uname: string, email: string): Promise<User> {
        const url = `${environment.adminApi}/users/current?uname=${uname}&email=${email}`;
        return this.http
            .get(url)
            .toPromise()
            .then((response) => (<any>response).json() as User)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    getAll() {
        return this.http
            .get(`${environment.adminApi}/users`, this.options)
            .pipe(map((response: any) => (<any>response).json()));
    }

    getById(_id: string) {
        return this.http
            .get(`${environment.adminApi}/${_id}`)
            .pipe(map((response: Response) => (<any>response).json()));
    }

    create(uname, email): Promise<User> {
        let userparm = {
            username: uname,
            email: email,
        };
        return this.http
            .post(`${environment.adminApi}/users/register`, JSON.stringify({ userparm }), {
                headers: this.headers,
            })
            .toPromise()
            .then((res) => (<any>res).json().data as User)
            .catch(this.handleError);
    }

    update(user: User) {
        return this.http.put(`${environment.adminApi}/users/${user._id}`, user, this.options);
    }

    delete(_id: string) {
        return this.http.delete(`${environment.adminApi}/users/${_id}`);
    }

    async getHostInfo() {
        let hostInfo = {};

        await $.getJSON('https://ipapi.co/json/').then((data) => {
            hostInfo = data;
            let username = '';

            if (this.cognitoUtil && this.cognitoUtil.getCurrentUser()) {
                username = this.cognitoUtil.getCurrentUser().getUsername();
            }

            this.userInfo = {
                hostInfo: hostInfo,
                userName: username,
            };
        });
        return this.userInfo;
    }

    getUserInfo() {
        if (!this.userInfo.userName) {
            return this.getHostInfo();
        } else {
            return this.userInfo;
        }
    }

    // service: create a user when a new user signin at first time
    userCreate(body) {
        return this.httpClient.post(`${environment.usersApi}/api/usercreate`, body, {
            headers: this.httpHeaders,
        });
    }

    // service: get user profile
    getProfile(curUserName) {
        return this.httpClient.get(`${environment.usersApi}/api/getprofile/${curUserName}`, {
            headers: this.httpHeaders,
        });
    }

    getModuleAccess(module) {
        return this.httpClient.get(`${environment.usersApi}/api/getmoduleaccess/${module}`, {
            headers: this.httpHeaders,
        });
    }

    getModuleAccessByBody(module, body) {
        return this.apiCachingService.getServiceData(
            'get',
            `${environment.usersApi}/api/getmoduleaccessbybody/${module}/${body}`,
            undefined,
            undefined,
            this.apiCachingService.C,
        );
        // return this.httpClient.get(
        //     `${environment.usersApi}/api/getmoduleaccessbybody/${module}/${body}`,
        //     {headers: this.httpHeaders})
    }

    createLog(type, moduleName, message) {
        let body = JSON.stringify({
            type,
            moduleName,
            message,
        });

        return this.httpClient.post(`${environment.usersApi}/api/logaccess`, body, {
            headers: this.httpHeaders,
        });
    }

    removeAllKeysOfRedis(moduleName: string, keyName: string[]) {
        return this.httpClient.post(
            `${environment.usersApi}/api/redis/remove-all-keys`,
            { moduleName, keyName },
            { headers: this.httpHeaders },
        );
    }

    getModuleNames() {
        return this.httpClient.post(
            `${environment.usersApi}/api/redis/get-module-names`,
            {},
            { headers: this.httpHeaders },
        );
    }

    getModuleKeys(moduleName: string) {
        return this.httpClient.post(
            `${environment.usersApi}/api/redis/get-module-keys`,
            { moduleName },
            { headers: this.httpHeaders },
        );
    }
}
