export const environment = {
    production: true,
    meetingsApiReport: 'https://igaeadecs.dev.un.int/',
    apiGateway: 'https://apis.igaead.dev.un.int/meetings',
    decisionsApi: 'https://igaeadecs.dev.un.int/decisions',
    meetingsApi: 'https://igaeadecs.dev.un.int/meetings',
    sdocumentsApi: 'https://igaeadecs.dev.un.int',
    mandatesApi: 'https://igaeadecs.dev.un.int/mandates',
    adminApi: 'https://igaeadecs.dev.un.int',
    socketApi: 'https://igaeadecs.dev.un.int',
    lookupsApi: 'https://igaeadecs.dev.un.int/lookups',
    agendasApi: 'https://igaeadecs.dev.un.int/agendas',
    statementsApi: 'https://igaeadecs.dev.un.int/statements',
    usersApi: 'https://igaeadecs.dev.un.int/users',
    votesApi: 'https://edelegateecs.dev.un.int/votes',
    apiKey: 'ZWRlbGVnYXRlOmFwaWtleTpkZXYK',
    publicPassword: 'ZWRlbGVnYXRlOnB1YmxpY3Bhc3N3b3JkOmRldgo=',
    region: 'us-east-1',
    identityPoolId: 'us-east-1:fbe0340f-9ffc-4449-a935-bb6a6661fd53',
    userPoolId: 'us-east-1_4x60cwDBX',
    provider: 'azuread',
    clientId: '7b19hi331irj5nlsd9p2ep0c4u',
    callbackURL: 'https://igaead.dev.un.int',
    signoutURL: 'https://igaead.dev.un.int',
    appWebDomain: 'igaeadplus.auth.us-east-1.amazoncognito.com',
    rekognitionBucket: 'rekognition-pics',
    albumName: 'usercontent',
    bucketRegion: 'us-east-1',
    ddbTableName: 'LoginTrail',
    cognito_idp_endpoint: '',
    cognito_identity_endpoint: '',
    sts_endpoint: '',
    dynamodb_endpoint: '',
    s3_endpoint: '',
    branch: 'DEV',
    reportingServer: 'https://jsreports-alb-484565455.us-east-1.elb.amazonaws.com',
    apikey: 'U2FsdGVkX19hSt9v8XmCAn9hrhGT5DQyQhlmzauHSZcHcPEhaqKN36D9D1gYktm7',
    jcmsPreviewUrl: 'https://journal.uat.un.org/en/new-york/preview/officials/',
};
