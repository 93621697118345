import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor() {}
    public socket = io(environment.socketApi, {
        path: '/sockets/socket.io/',
        transports: ['websocket'],
        upgrade: true,
        secure: true,
    });
}
