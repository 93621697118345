import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class AccessService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient) {}

    getUserList() {
        return this.http.get(`${environment.usersApi}/api/getuserlist`, { headers: this.headers });
    }

    sTemplates() {
        return this.http.post(
            `${environment.reportingServer}/api/report`,
            {
                template: { shortid: 'ltw4jM8z6g', recipe: 'docx' },
                data: {
                    customer: 'Walker Group',
                    month: 'April',
                    taxPercentage: 0.2,
                    detail: [
                        {
                            date: '2019-04-03',
                            product: 'Vitamin C',
                            category: 'Health',
                            unitPrice: 25,
                            quantity: 1,
                            discountPercentage: 0,
                        },
                        {
                            date: '2019-04-03',
                            product: 'Probiotics',
                            category: 'Health',
                            unitPrice: 83,
                            quantity: 1,
                            discountPercentage: 0.25,
                        },
                        {
                            date: '2019-04-04',
                            product: 'Mild Bubble Cleanser',
                            category: 'Cleansing',
                            unitPrice: 13,
                            quantity: 2,
                            discountPercentage: 0,
                        },
                        {
                            date: '2019-04-04',
                            product: 'Deep Cleanser',
                            category: 'Cleansing',
                            unitPrice: 12,
                            quantity: 3,
                            discountPercentage: 0,
                        },
                        {
                            date: '2019-04-04',
                            product: 'Atomy Men Set',
                            category: 'Men Skin Care',
                            unitPrice: 54,
                            quantity: 1,
                            discountPercentage: 0.35,
                        },
                        {
                            date: '2019-04-09',
                            product: 'BB Cream',
                            category: 'Make-Up',
                            unitPrice: 12,
                            quantity: 3,
                            discountPercentage: 0,
                        },
                        {
                            date: '2019-04-15',
                            product: 'Lipstick Poppy',
                            category: 'Make-Up',
                            unitPrice: 22,
                            quantity: 1,
                            discountPercentage: 0,
                        },
                        {
                            date: '2019-04-15',
                            product: 'Healthy Glow Base',
                            category: 'Make-Up',
                            unitPrice: 18,
                            quantity: 2,
                            discountPercentage: 0.12,
                        },
                        {
                            date: '2019-04-26',
                            product: 'Lotion',
                            category: 'Skin Care',
                            unitPrice: 23,
                            quantity: 1,
                            discountPercentage: 0,
                        },
                    ],
                },
            },
            { responseType: 'text' },
        );
    }

    reportsData(queryparam) {
        return this.http.get(`${environment.meetingsApi}${queryparam}`, {
            headers: this.headers,
        });
    }

    getEvotesData() {
        return this.http.get(`${environment.apiGateway}/api/public/api?env=uat&type=evotes`, {
            headers: this.headers,
        });
    }
    getTemplates() {
        return this.http.get(
            `${environment.meetingsApi}/api/getTemplates?env=${environment.branch.toLowerCase()}`,
            {
                headers: this.headers,
            },
        );
    }
    getFilteredMeetingsData() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/getmtconfsfiltered?apikey=${environment.apikey}&env=uat`,
            {
                headers: this.headers,
            },
        );
    }

    getUserByID(id) {
        return this.http.get(`${environment.usersApi}/api/getuser/${id}`, {
            headers: this.headers,
        });
    }

    updateUserByID(user) {
        return this.http.put(
            `${environment.usersApi}/api/updateuser/${user._id}`,
            { user: user },
            { headers: this.headers },
        );
    }
}
