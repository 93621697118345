
  <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun [ngClass]="{'uat-background': environmentTag === 'uat'}">
    <div class="additional-bg">
    </div>
      
    
    <div >
        <div class="auth-ctn">
            <div *ngIf="!hideSignin" class="auth-box">
                <div class="auth-block">                
                        <div *ngIf="errorMessage!=null" class="alert alert-danger">
                                {{ errorMessage }}
                            </div>
                            <div class="form-group row">
                                <h1 translate>Sign in to iGAEAD+</h1>
                            </div>            
                           <div><button class=" form-group btn  btn-lg" (click) = "getAuthSession()"  type="button"><i class="fa fa-lock fa-fw"></i>
                            Login using Azure AD
                        </button></div>

                        <div *ngIf="!environment.production">Click <a [routerLink]="['signin']" id="here">here</a> to signin with iGAEAD+ login details</div>

                </div> 
            </div>
        </div>
    </div>
    
    <router-outlet *ngIf="hideSignin"></router-outlet>
    <simple-notifications [options]="options"></simple-notifications>
    <p-dialog [closable]="true" [(visible)]="idleState.visible" [contentStyle]="{ 'width': '600px' }" [baseZIndex]="10000">
      <p-header class="">Session about to timeout</p-header>
      <div class="p-2 alert m-0 p-0 h4 ui-dialog-counter" role="alert">
          <p class="mb-0">
              <strong>
                  <span class="text-warning h1 mr-2 align-middle"><i class="fa fa-fw fa-clock-o"></i></span>
                  <span class="align-middle h6">{{idleState.message}}</span>
              </strong>
          </p>
      </div>
    </p-dialog>
</main>
    
   

