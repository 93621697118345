import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserLoginService } from 'app/shared/_services/user-login.service';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { EAccessType, EAccessTypePriority } from '../../../enums/EAccessType';
import { IJCMSSummarySubmissions } from '../../../interfaces/IJCMSSummarySubmissions';
import { IJournalSummary } from '../../../interfaces/IJournalSummary';
import { IJCMSSegmentToTemplateMapping } from '../../../interfaces/IJCMSSegmentToTemplateMapping';
import {
    EItemType,
    IMTSegmentEntity,
    IProcedureStepEntity,
    ITemplateAndPSAndSegment,
    ITemplateItem,
} from '../../../interfaces/IMeeting';
import {
    IProcedureStepTypeLookup,
    IPST_TemplateMapping,
} from '../../../interfaces/IProcedureStepTypeLookup';
import { ILookup } from '../../../interfaces/ILookup';
import { ESelectorType } from '../../../interfaces/ITemplateMatchingDataPointLookup';
import { IIGAEADValueObj, IJCMSLookup, INameId } from '../../../interfaces/INameId';
import * as _ from 'lodash';
import { IUser } from '../../../interfaces/IUser';

declare let stringSimilarity: any;

@Injectable()
export class LookupService {
    public jcmsValueReplaceRegex = /[’']/gi;
    public headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
    });
    public primary = ' ✅';

    private k: any;
    private token;

    public lookups = [
        {
            lookups: 'Level of Representations',
        },
        {
            lookups: 'Titles',
        },
        {
            lookups: 'Implementers',
        },
        {
            lookups: 'Bodies',
        },
        {
            lookups: 'Agenda Bodies',
        },
        {
            lookups: 'Sessions',
        },
        {
            lookups: 'Presiding Officers',
        },
        {
            lookups: 'Items Before Agendas',
        },
        {
            lookups: 'Items After Agendas',
        },
        {
            lookups: 'Agendas',
        },
        {
            lookups: 'Meeting Types',
        },
        {
            lookups: 'Entities',
        },
        {
            lookups: 'Procedure Step Types',
        },
        {
            lookups: 'JCMS Data Points',
        },
        {
            lookups: 'JCMS Helpers',
        },
        {
            lookups: 'JCMS Template Params To JCMS Lookup',
        },
        {
            lookups: 'JCMS Template Params To Data Points',
        },
        {
            lookups: 'JCMS excluded templates',
        },
        {
            lookups: 'JCMS Segment To Template Mapping',
        },
        {
            lookups: 'Meeting Collection Fields',
        },
        {
            lookups: 'Tags',
        },
        {
            lookups: 'Procedure Patterns',
        },
        {
            lookups: 'Reporting',
        },
        {
            lookups: 'Procedure Path',
        },
        {
            lookups: 'Agenda headings',
        },
        {
            lookups: 'Document Types',
        },
        {
            lookups: 'Meeting List',
        },
        {
            lookups: 'Public Endpoint',
        },
    ];

    constructor(
        private http: HttpClient,
        private userLoginService: UserLoginService,
    ) {
        this.userLoginService.isAuthenticated(this);
    }

    isLoggedIn(token, session) {
        if (session) {
            this.token = token;
            this.headers.append('authorization', token);
        }
    }

    onOrderFocus(e) {
        this.k = parseInt(e.target.value) - 1;
    }

    countLookups(data) {
        var count = 0;
        data.forEach((item) => {
            if (!item['LKP_Archived']) {
                count++;
            }
        });
        return count;
    }

    onOrderChange(e, data) {
        if (parseInt(e.target.value) <= this.countLookups(data) && parseInt(e.target.value) >= 1) {
            const curItem = data[this.k];
            data.splice(this.k, 1);
            data.splice(e.target.value - 1, 0, curItem);
        } else {
            e.target.value = this.k + 1;
        }
    }

    reOrderList(data) {
        var count = 1;
        data.forEach((item) => {
            if (!item['LKP_Archived']) {
                item['LKP_Order'] = count++;
            }
        });
    }

    getCodeByName(name) {
        switch (name) {
            case 'Level of Representations':
                return 'LOR';
            case 'Titles':
                return 'TITLE';
            case 'Implementers':
                return 'IMPL';
            case 'Sessions':
                return 'SESS';
            case 'Items Before Agendas':
                return 'IBA';
            case 'Items After Agendas':
                return 'IAA';
            case 'Meeting Collection Fields':
                return 'MTNG_COLLECTION_FIELDS';
            case 'Bodies':
                return 'BODY';
            case 'Meeting Types':
                return 'MEETINGTYPES';
            case 'Tags':
                return 'TAG';
            case 'Agenda headings':
                return 'AHEAD';
            case 'Document Types':
                return 'DOCTYPES';
            case 'Agenda Bodies':
                return 'AGENDABODY';
            case 'Clusters':
                return 'CLUSTER';
        }
    }

    getLookupData(name) {
        const code = this.getCodeByName(name);
        return this.http.get(`${environment.lookupsApi}/api/lookups/getlookups/${code}`, {
            headers: this.headers,
        });
    }

    getReport(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/report?env=${environment.branch.toLowerCase()}`,
            data,
            { headers: this.headers },
        );
    }

    getPresidingOfficerData() {
        return this.http.get(`${environment.lookupsApi}/api/lookups/getpresidingofficers`, {
            headers: this.headers,
        });
    }

    getAgenda() {
        return this.http.get(`${environment.lookupsApi}/api/lookups/getagenda`, {
            headers: this.headers,
        });
    }

    updateAgendaLookup(item) {
        return this.http.put(
            `${environment.lookupsApi}/api/lookups/updateagenda`,
            { agenda: item },
            { headers: this.headers },
        );
    }

    createAgendaLookup(item) {
        return this.http.post(
            `${environment.lookupsApi}/api/lookups/createagenda`,
            { agenda: item },
            { headers: this.headers },
        );
    }

    deleteAgendaLookup(id) {
        return this.http.post(
            `${environment.lookupsApi}/api/lookups/deleteagenda`,
            { id: id },
            { headers: this.headers },
        );
    }

    getEntities() {
        return this.http.get(`${environment.lookupsApi}/api/lookups/getentities`, {
            headers: this.headers,
        });
    }

    getReporties() {
        return this.http.get(`${environment.lookupsApi}/api/lookups/getreport`, {
            headers: this.headers,
        });
    }

    getLookupColumn(data) {
        var columns = [];

        for (var property in data[0]) {
            if (
                property !== '_id' &&
                property !== 'LKP_Code' &&
                property !== 'LKP_Title' &&
                property !== 'LKP_Archived'
            ) {
                if (property === 'LKP_ValueShort') {
                    if (
                        data[0]['LKP_Code'] !== 'IBA' &&
                        data[0]['LKP_Code'] !== 'IAA' &&
                        data[0]['LKP_Code'] !== 'MTNG_COLLECTION_FIELDS'
                    ) {
                        columns.push(property);
                    }
                } else {
                    columns.push(property);
                }
            }
        }

        return JSON.parse(JSON.stringify(columns));
    }

    getPresidingOfficerColumn(data) {
        var columns = [];

        for (var property in data[0]) {
            if (property !== '_id') {
                columns.push(property);
            }
        }

        return JSON.parse(JSON.stringify(columns));
    }

    getAgendaColumn(data) {
        var columns = [];

        for (var property in data[0]) {
            if (property !== '_id') {
                columns.push(property);
            }
        }

        return JSON.parse(JSON.stringify(columns));
    }

    updateLookupData(data) {
        var ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updatelookups`,
                        { lookups: item },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createlookups`,
                        { lookups: item },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updatePresidingOfficerData(data) {
        var ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updatepresidingofficers`,
                        { lookups: item },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createpresidingofficers`,
                        { lookups: item },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updateAgenda(data) {
        const ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updateagenda`,
                        { agenda: item },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createagenda`,
                        { agenda: item },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updateEntities(data) {
        const ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updateentity`,
                        { entities: item },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createentity`,
                        { entities: item },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updateReportings(data) {
        const ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updatereport`,
                        {
                            RP_DataSource: item.RP_DataSource,
                            RP_Template: item.RP_Template,
                            RP_Recipe: item.RP_Recipe,
                            RP_Name: item.RP_Name,
                            AG_Archived: item.AG_Archived,
                            AG_isUpdated: item.AG_isUpdated,
                            _id: item._id,
                        },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createreport`,
                        {
                            RP_DataSource: item.RP_DataSource,
                            RP_Template: item.RP_Template,
                            RP_Name: item.RP_Name,
                            RP_Recipe: item.RP_Recipe,
                            AG_Archived: item.AG_Archived,
                            AG_isUpdated: false,
                        },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    extractParameterFromTemplateText(
        templateText: string,
        allTemplateParameterList: string[],
    ): string[] {
        if (!templateText) return [];
        let parameters = [];
        let templateParameters = templateText.match(/\[.*?\]/gm) || [];
        for (let templateParameter of templateParameters) {
            let parameterWithoutBrackets = templateParameter.replace(/(\[|\])/gi, '');
            if (allTemplateParameterList.indexOf(parameterWithoutBrackets) >= 0)
                parameters.push(parameterWithoutBrackets);
        }
        return parameters;
    }

    rebuildTemplateFromParamChange(template: string, newParams: string[], allParams: string[]) {
        const replaceStr = '।।।।_।।।।';
        let newTemplate = template;
        let existingParams = this.extractParameterFromTemplateText(template, allParams);

        if (
            existingParams.length !== newParams.length ||
            newParams.length === 0 ||
            existingParams.length === 0
        ) {
            if (newParams.length !== 0 || existingParams.length !== 0) {
                console.warn(
                    `[SYS] Param length mismatch. existingParams count: ${existingParams.length}, newParams count: ${newParams.length}`,
                );
                console.log('[SYS] ' + JSON.stringify(existingParams, null, 4));
                console.log('[SYS] ' + JSON.stringify(newParams, null, 4));
            }
            return '';
        }

        for (let existingParam of existingParams)
            newTemplate = newTemplate.replace(`[${existingParam}]`, `[${replaceStr}]`);
        for (let newParam of newParams)
            newTemplate = newTemplate.replace(`[${replaceStr}]`, `[${newParam}]`);
        return newTemplate;
    }

    getJournalOrgansList() {
        return this.http.post<object[]>(
            `${environment.meetingsApi}/api/meetings/get-journal-organs-list`,
            {},
            { headers: this.headers },
        );
    }

    async getJCMSLookupsByName(
        names: string[],
        meetingBody,
    ): Promise<[{ name: string; values: any[] }]> {
        if (_.isEmpty(names)) return <any>[];
        let output = <any>(
            await this.http
                .post<object[]>(
                    `${environment.meetingsApi}/api/meetings/get-journal-lookups-by-name`,
                    { names, meetingBody },
                    { headers: this.headers },
                )
                .toPromise()
        );
        return output.result;
    }

    async getJCMSLookupsByNameSticky(
        names: string[],
        meetingBody,
        createdLookups: IJCMSLookup[],
    ): Promise<[{ name: string; values: any[] }]> {
        if (_.isEmpty(names)) return <any>[];

        let resultArrPromises: [{ name: string; values: any[] }] = <any>[];
        for (let name of names) {
            let createdLookupsOfName = <any>_.filter(createdLookups || <any>[], { Name: name });
            resultArrPromises.push(
                await this.getLookupAndRetryIfCreatedLookupNotReceived(
                    name,
                    meetingBody,
                    createdLookupsOfName,
                    1,
                ),
            );
        }
        return <any>await Promise.all(resultArrPromises);
    }

    // All of createdLookups should present in get API call.
    private async getLookupAndRetryIfCreatedLookupNotReceived(
        name: string,
        meetingBody,
        createdLookups: IJCMSLookup[],
        counter: number,
    ): Promise<{ name: string; values: any[] }> {
        let output = <any>(
            await this.http
                .post<object[]>(
                    `${environment.meetingsApi}/api/meetings/get-journal-lookups-by-name`,
                    { names: [name], meetingBody },
                    { headers: this.headers },
                )
                .toPromise()
        );
        let result: { name: string; values: IJCMSLookup[] } = output.result[0] || {};
        result.values = result.values || [];

        let allFound = true;
        for (let createdLookup of createdLookups) {
            let foundCreated = _.find(result.values, { Id: createdLookup.Id });
            if (!foundCreated) {
                allFound = false;
                break;
            }
        }

        if (allFound) return result;
        else {
            if (counter <= 36) {
                console.log(`[SYS] Try again to get created item : ${createdLookups[0].Value}`);
                await new Promise((r) => setTimeout(r, 4000));
                return await this.getLookupAndRetryIfCreatedLookupNotReceived(
                    name,
                    meetingBody,
                    createdLookups,
                    ++counter,
                );
            } else return result;
        }
    }

    getJournalTemplateListByMeetingId(meetingId) {
        return this.http.post<object[]>(
            `${environment.meetingsApi}/api/meetings/get-journal-template-list-by-meeting-id`,
            { meetingId },
            { headers: this.headers },
        );
    }

    getJournalTemplateList(organName: string) {
        return this.http.post<object[]>(
            `${environment.meetingsApi}/api/meetings/get-journal-template-list-by-organ-name`,
            { organName },
            { headers: this.headers },
        );
    }

    getAllJournalTemplateList(): Promise<{
        result: { templateList: IJCMSLookup[]; parameterList: string[] };
    }> {
        return <any>(
            this.http
                .post<object[]>(
                    `${environment.meetingsApi}/api/meetings/get-journal-all-template-list`,
                    {},
                    { headers: this.headers },
                )
                .toPromise()
        );
    }

    getTemplateMatchingDataPointList() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-template-matching-data-point-list`,
            { headers: this.headers },
        );
    }

    createTemplateMatchingDataPointList(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-template-matching-data-point-list`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    updateTemplateMatchingDataPointList(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-template-matching-data-point-list`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    deleteTemplateMatchingDataPointList(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-template-matching-data-point-list`,
            data,
            { headers: this.headers },
        );
    }

    getTemplateParamToLookupMapping() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-template-param-to-lookup-mapping`,
            { headers: this.headers },
        );
    }

    createTemplateParamToLookupMapping(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-template-param-to-lookup-mapping`,
            data,
            { headers: this.headers },
        );
    }

    updateTemplateParamToLookupMapping(data) {
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-template-param-to-lookup-mapping`,
            data,
            { headers: this.headers },
        );
    }

    deleteTemplateParamToLookupMapping(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-template-param-to-lookup-mapping`,
            data,
            { headers: this.headers },
        );
    }

    getJCMSExcludedTemplates() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-jcms-excluded-templates`,
            { headers: this.headers },
        );
    }

    createJCMSExcludedTemplates(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-jcms-excluded-templates`,
            data,
            { headers: this.headers },
        );
    }

    updateJCMSExcludedTemplates(data) {
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-jcms-excluded-templates`,
            data,
            { headers: this.headers },
        );
    }

    deleteJCMSExcludedTemplates(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-jcms-excluded-templates`,
            data,
            { headers: this.headers },
        );
    }

    getJCMSHelpers() {
        return this.http.get(`${environment.meetingsApi}/api/meetings/get-jcms-helpers`, {
            headers: this.headers,
        });
    }

    createJCMSHelpers(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-jcms-helpers`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    updateJCMSHelpers(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-jcms-helpers`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    deleteJCMSHelpers(data) {
        return this.http.post(`${environment.meetingsApi}/api/meetings/delete-jcms-helpers`, data, {
            headers: this.headers,
        });
    }

    getJCMSSegmentToTemplateMapping() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-jcms-segment-to-template-mapping`,
            { headers: this.headers },
        );
    }

    createJCMSSegmentToTemplateMapping(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-jcms-segment-to-template-mapping`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    updateJCMSSegmentToTemplateMapping(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-jcms-segment-to-template-mapping`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    deleteJCMSSegmentToTemplateMapping(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-jcms-segment-to-template-mapping`,
            data,
            { headers: this.headers },
        );
    }

    getTemplateParamsToDataPoint() {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-template-params-to-data-point`,
            { headers: this.headers },
        );
    }

    getTemplateParamsToDataPointById(templateID) {
        return this.http.get(
            `${environment.meetingsApi}/api/meetings/get-template-params-to-data-point/get-by-id/${templateID}`,
            { headers: this.headers },
        );
    }

    createTemplateParamsToDataPoint(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-template-params-to-data-point`,
            data,
            { headers: this.headers },
        );
    }

    updateTemplateParamsToDataPoint(data) {
        return this.http.put(
            `${environment.meetingsApi}/api/meetings/update-template-params-to-data-point`,
            data,
            { headers: this.headers },
        );
    }

    deleteTemplateParamsToDataPoint(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-template-params-to-data-point`,
            data,
            { headers: this.headers },
        );
    }

    findDataByModelAndId(modelName, id) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/find-data-by-model-and-id`,
            { modelName, id },
            { headers: this.headers },
        );
    }

    createTemplateLogs(data) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-template-logs`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    createJournalSummariesSubmissions(data: IJCMSSummarySubmissions) {
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-journal-summaries-submissions`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    async getLookupDataByName(loadedLookupsMap, lookupName: string) {
        if (loadedLookupsMap[lookupName]) return loadedLookupsMap[lookupName];
        else {
            let resp: ILookup[] = <any>await this.getLookupData(lookupName).toPromise();
            let dataArr: INameId[] = [];
            for (let item of resp)
                dataArr.push({ _id: item._id, name: item.LKP_Value, original: item });
            loadedLookupsMap[lookupName] = <any>dataArr;
            return dataArr;
        }
    }

    async loadRequiredIGAEADLookupDataForPSTTemplateMappings(
        loadedLookupsMap,
        ourSelectorsAndDataObjectsMap,
        pstTemplateMappings: IPST_TemplateMapping[] | IJCMSSegmentToTemplateMapping[],
    ) {
        if (!pstTemplateMappings || !pstTemplateMappings.length) return;
        let promiseList = [];
        let idArr = [];
        for (let pstTemplateMapping of pstTemplateMappings) {
            let selectors =
                (<IPST_TemplateMapping>pstTemplateMapping).selectors ||
                (<IJCMSSegmentToTemplateMapping>pstTemplateMapping).STT_Selectors;

            for (let selector of selectors || []) {
                let selectorObj = ourSelectorsAndDataObjectsMap[selector.id];
                if (selectorObj?.TMD_type === ESelectorType.LOOKUP) {
                    idArr.push(selector.id);
                    promiseList.push(
                        this.getLookupDataByName(loadedLookupsMap, selectorObj.TMD_lookupName),
                    );
                }
            }
        }
        let outputArr = <any>await Promise.all(promiseList);
        for (let i = 0; i < idArr.length; i++) {
            let id = idArr[i];
            ourSelectorsAndDataObjectsMap[id].TMD_value = outputArr[i];
        }
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    createJCMSLookup(type, value) {
        let email = this.getUserEmail();
        let body = { type, value, email };
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(body))),
        };

        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-jcms-lookup`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    createJCMSTemplate(templateText: string, selectedBodyID) {
        let email = this.getUserEmail();
        let body = { templateText, selectedBodyID, email };
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(body))),
        };

        return this.http.post(
            `${environment.meetingsApi}/api/meetings/create-jcms-template`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    getUserEmail() {
        let storage = localStorage.getItem('user');
        let userObj = JSON.parse(storage);
        return userObj.USR_username.replace('azuread_', '');
    }

    getUserNameOrEmail() {
        let storage = localStorage.getItem('user');
        let userObj = JSON.parse(storage);
        let name = ((userObj.USR_FirstName || '') + ' ' + (userObj.USR_LastName || '')).trim();
        return name || this.getUserEmail();
    }

    getProcedureStepsCombinations() {
        return this.http.get(
            `${environment.lookupsApi}/api/lookups/getProcedureStepsCombinations`,
            {
                headers: this.headers,
            },
        );
    }

    getMeetingListCombinations() {
        return this.http.get(`${environment.lookupsApi}/api/lookups/getMeetingListCombinations`, {
            headers: this.headers,
        });
    }

    getPublicEndpointCombinations() {
        return this.http.get(
            `${environment.lookupsApi}/api/lookups/getPublicEndpointCombinations`,
            {
                headers: this.headers,
            },
        );
    }

    updateProcedurepath(data) {
        const ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updateProcedureStepCombiation`,
                        {
                            PS_Name: item.PS_Name,
                            PS_List: item.PS_List,
                            PS_Template: item.PS_Template,
                            PS_Archived: item.PS_Archived || false,
                            _id: item._id,
                            PS_Recipe: item.PS_Recipe,
                        },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createProcedureStepCombiation`,
                        {
                            PS_Name: item.PS_Name,
                            PS_List: item.PS_List,
                            PS_Template: item.PS_Template,
                            PS_Archived: item.PS_Archived || false,
                            PS_Recipe: item.PS_Recipe,
                        },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updateMeetingList(data) {
        const ObservableList = [];

        data.forEach((item) => {
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updateMeetingListCombiation`,
                        {
                            MT_Name: item.MT_Name,
                            MT_List: item.MT_List,
                            MT_Template: item.MT_Template,
                            MT_Archived: item.MT_Archived || false,
                            _id: item._id,
                            MT_Recipe: item.MT_Recipe,
                        },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createMeetingListCombiation`,
                        {
                            MT_Name: item.MT_Name,
                            MT_List: item.MT_List,
                            MT_Template: item.MT_Template,
                            MT_Archived: item.MT_Archived || false,
                            MT_Recipe: item.MT_Recipe,
                        },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    updatePublicEndpointList(data) {
        const ObservableList = [];

        data.forEach((item) => {
            console.log('item', item);
            if (item['_id']) {
                ObservableList.push(
                    this.http.put(
                        `${environment.lookupsApi}/api/lookups/updatePublicEndpointCombiation`,
                        {
                            PE_Name: item.PE_Name,
                            PE_Type: item.PE_Type,
                            PE_TypeValue: Array.isArray(item.PE_TypeValue)
                                ? item.PE_TypeValue
                                : [item.PE_TypeValue],
                            PE_Body: item.PE_Body !== '' ? item.PE_Body : [],
                            PE_Session: item.PE_Session !== '' ? item.PE_Session : [],
                            PE_FileName: item.PE_FileName,
                            PE_EmailList: item.PE_EmailList,
                            PE_TemplateText: item.PE_TemplateText,
                            PE_Archived: item.PE_Archived || false,
                            _id: item._id,
                        },
                        { headers: this.headers },
                    ),
                );
            } else {
                ObservableList.push(
                    this.http.post(
                        `${environment.lookupsApi}/api/lookups/createPublicEndpintCombiation`,
                        {
                            PE_Name: item.PE_Name,
                            PE_Type: item.PE_Type,
                            PE_TypeValue: Array.isArray(item.PE_TypeValue)
                                ? item.PE_TypeValue
                                : [item.PE_TypeValue],
                            PE_Body: item.PE_Body !== '' ? item.PE_Body : [],
                            PE_Session: item.PE_Session !== '' ? item.PE_Session : [],
                            PE_FileName: item.PE_FileName,
                            PE_EmailList: item.PE_EmailList,
                            PE_TemplateText: item.PE_TemplateText,
                            PE_Archived: item.PE_Archived || false,
                        },
                        { headers: this.headers },
                    ),
                );
            }
        });

        return forkJoin(ObservableList);
    }

    templateRecipe() {
        return [
            'docx',
            'chrome-pdf',
            'chrome-image',
            'html-with-browser-client',
            'pptx',
            'text',
            'static-pdf',
            'html-to-xlsx',
            'xlsx',
            'html',
        ];
    }

    generatePlainTemplateTextInLookup(
        journalTemplatesList: any[],
        textProperty: string,
        textPlainProperty: string,
    ) {
        let jqObj = $('#idTemplateTextTemplateMapping');
        for (let item of journalTemplatesList || []) {
            jqObj.html(item[textProperty]);
            item[textPlainProperty] = (jqObj.text() || '').trim() || item[textProperty];
        }
    }

    getPlainTextFromHtmlText(htmlText) {
        let jqObj = $('#idTemplateTextTemplateMapping');
        jqObj.html(htmlText);
        return (jqObj.text() || '').trim();
    }

    generatePropertyForSorting(
        journalTemplatesList: any[],
        textProperty: string,
        newPropertyName: string,
    ) {
        for (let item of journalTemplatesList || []) {
            let value: string = item?.[textProperty] || '';
            value = value.replace(/[\. \(\)\[\]\*\-\:]/gi, '');
            item[newPropertyName] = value;
        }
    }

    sortArray<T>(arr: T[], field: keyof T) {
        return _.orderBy(
            arr,
            [
                (item: any) => {
                    return (item[field] || '').toLowerCase();
                },
            ],
            ['asc'],
        );
    }

    async getJournalSummary(meetingID): Promise<IJournalSummary> {
        let resp = <any>(
            await this.http
                .get(
                    `${environment.meetingsApi}/api/meetings/get-journal-summary/get-by-id/${meetingID}`,
                    { headers: this.headers },
                )
                .toPromise()
        );
        const summary: IJournalSummary = resp?.result;

        if (summary?.JS_Summary?.length) {
            // remove fa icons to display in UI.
            const newSummaryArr: string[] = [];
            for (let summaryLine of summary.JS_Summary) {
                let newLine = summaryLine || '';
                newLine = newLine.replace(/<i name="nameEditSubTemplate.*?<\/i>/gm, '');
                newLine = newLine.replace(/<i name="nameDeleteSubTemplate.*?<\/i>/gm, '');
                newLine = newLine.replace(
                    /<i name="nameChangeSummaryLineSubTemplate.*?<\/i>/gm,
                    '',
                );
                newSummaryArr.push(newLine);
            }
            summary.JS_Summary = newSummaryArr;
        }

        return summary;
    }

    saveJournalSummary(data) {
        console.log(data);
        const bodyPayload = {
            data: btoa(encodeURIComponent(JSON.stringify(data))),
        };
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/save-journal-summary`,
            bodyPayload,
            { headers: this.headers },
        );
    }

    deleteJournalSummary(data) {
        return this.http.post(
            `${environment.meetingsApi}/api/meetings/delete-journal-summary`,
            data,
            { headers: this.headers },
        );
    }

    getCalculatedAccessTypeMeetings(moduleName, body) {
        let allAccessTypes: { type: EAccessType; weight: number }[] = [];
        let userStr = localStorage.getItem('user');
        try {
            let user: IUser = JSON.parse(userStr);
            let accessList = user?.USR_Access || [];
            for (let access of accessList) {
                if (access.moduleName === moduleName) {
                    if ((access.bodies || []).indexOf(body) >= 0)
                        allAccessTypes.push({
                            type: <any>access.accessType,
                            weight: EAccessTypePriority[access.accessType],
                        });
                }
            }

            allAccessTypes = <any>_.orderBy(allAccessTypes, ['weight'], ['desc']);
            return allAccessTypes?.[0]?.type;
        } catch (e) {
            console.log(e);
        }
    }

    getHash(str: string | any, isPositive?: boolean): string {
        let hash = 0;
        let i;
        let chr;
        str = typeof str === 'object' ? JSON.stringify(str) : str;
        if (!str || str.length === 0) return hash.toString();
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        if (isPositive) return Math.abs(hash).toString();
        else return hash.toString();
    }

    /**
     * Sort lookups based on their similarity weight.
     * If JCMS values are provided, shift them to top.
     */
    sortLookups(
        lookups: IJCMSLookup[],
        matchingStrings: (string | IIGAEADValueObj)[],
        jcmsValues: string[],
    ): IJCMSLookup[] {
        if (!lookups?.length) return [];
        if (!matchingStrings) return [...lookups];
        if (!matchingStrings?.length) return [...lookups];

        let sortMeArr: IJCMSLookup[] = JSON.parse(JSON.stringify(lookups));
        for (let matchingString of matchingStrings) {
            for (let item of sortMeArr) {
                let compareWithString;
                if (typeof matchingString === 'object') compareWithString = matchingString.value;
                else compareWithString = matchingString;

                item.Weight = (item.Weight || 0) + this.compareString(item.Text, compareWithString);
            }
        }
        sortMeArr.sort((a, b) => b.Weight - a.Weight);

        // shift matched values to top in array
        if (jcmsValues && jcmsValues.length) {
            let jcmsLookupArr = [];
            for (let jcmsValue of jcmsValues) {
                let item = _.find(sortMeArr, { Id: jcmsValue });
                if (item) jcmsLookupArr.push(item);
            }

            if (jcmsLookupArr.length) {
                _.pullAll(sortMeArr, jcmsLookupArr);
                sortMeArr.unshift(...jcmsLookupArr);
            }
        }

        return sortMeArr;
    }

    private compareString(a: string, b: string) {
        let aPlain = (a || '').toLowerCase().replace(this.jcmsValueReplaceRegex, '');
        let bPlain = (b || '').toLowerCase().replace(this.jcmsValueReplaceRegex, '');
        return stringSimilarity.compareTwoStrings(aPlain, bPlain);
    }

    /**
     * Sort templates based on their similarity weight.
     * If JCMS values are provided, shift them to top.
     */
    filterTemplates(
        templates: IJCMSLookup[],
        journalTemplatesListMapId: { [Id: string]: IJCMSLookup },
        templateItem: ITemplateAndPSAndSegment,
        psMap: { [_id: string]: IProcedureStepTypeLookup },
    ): IJCMSLookup[] {
        if (!templates?.length) return [];
        if (templateItem.loadAllTemplatesForSuperUser)
            return _.sortBy(templates, <keyof IJCMSLookup>'TextPlain');

        let ps: IProcedureStepTypeLookup = psMap[templateItem.PS_type];
        if (ps?.PST_TemplateAlternative?.length) {
            let filteredTemplates: IJCMSLookup[] = [];
            let pushedTemplates: Map<string, boolean> = new Map(); // templateID -> boolean

            // insert templates of template mappings.
            for (let templateMapping of ps.PST_TemplateMappings) {
                if (!pushedTemplates.get(templateMapping.templateID)) {
                    let tempTemplate = journalTemplatesListMapId[templateMapping.templateID];
                    if (tempTemplate) {
                        filteredTemplates.push(tempTemplate);
                        pushedTemplates.set(templateMapping.templateID, true);
                    }
                }
            }

            // insert alternative templates
            for (let alternateTemplate of ps.PST_TemplateAlternative) {
                if (!pushedTemplates.get(alternateTemplate.templateID)) {
                    let tempTemplate = journalTemplatesListMapId[alternateTemplate.templateID];
                    if (tempTemplate) {
                        filteredTemplates.push(tempTemplate);
                        pushedTemplates.set(alternateTemplate.templateID, true);
                    }
                }
            }

            return _.sortBy(filteredTemplates, <keyof IJCMSLookup>'TextPlain');
        } else return _.sortBy(templates, <keyof IJCMSLookup>'TextPlain');
    }

    getJCMSSummaryLine(
        procedureStepModel: IProcedureStepEntity | IMTSegmentEntity,
        journalSummary: IJournalSummary,
        seqNo,
        from: EItemType,
    ) {
        let summaryOutput = '';
        let foundUUID = false;
        const seqNoTemp = (seqNo || '').toString().replace('.', '_');

        for (let key in journalSummary?.JS_State || {}) {
            let state: IProcedureStepEntity = journalSummary?.JS_State?.[key];
            if (state?.PS_id) foundUUID = true;
            if (state?.PS_id === (<IProcedureStepEntity>procedureStepModel)?._id)
                summaryOutput = (<ITemplateItem>(<any>state))?.jcmsSummaryOutputUI;
            if (from === EItemType.SEGMENT && key === seqNoTemp)
                summaryOutput = (<ITemplateItem>(<any>state))?.jcmsSummaryOutputUI;
        }

        if (summaryOutput) return summaryOutput;
        else if (
            !foundUUID &&
            (<IProcedureStepEntity>procedureStepModel)?.PS_type ===
                journalSummary?.JS_State?.[seqNoTemp]?.PS_type
        )
            return journalSummary?.JS_State?.[seqNoTemp]?.jcmsSummaryOutputUI || '';
    }

    public generateMongoObjectId() {
        const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
        return (
            timestamp +
            'xxxxxxxxxxxxxxxx'
                .replace(/[x]/g, () => {
                    return ((Math.random() * 16) | 0).toString(16);
                })
                .toLowerCase()
        );
    }
}
