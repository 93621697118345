import { Routes, RouterModule } from '@angular/router';
import { Pages } from './pages.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from './../shared/_guards/index';
import { ReportComponent } from './meetings/components/report/report.component';
import { ReportGenerationComponent } from './report-generation/report-generation.component';
// noinspection TypeScriptValidateTypes

// export function loadChildren(path) { return System.import(path); };

const routes: Routes = [
    {
        path: 'signin',
        loadChildren: () => import('app/pages/signin/signin.module').then((m) => m.SigninModule),
    },
    {
        path: 'register',
        loadChildren: () =>
            import('app/pages/register/register.module').then((m) => m.RegisterModule),
    },
    {
        path: '',
        component: Pages,
        children: [
            { path: '', redirectTo: 'meetings', pathMatch: 'full' },
            { path: 'report', component: ReportGenerationComponent },
            // { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
            {
                path: 'meetings',
                loadChildren: () =>
                    import('./meetings/meetings.module').then((m) => m.MeetingsModule),
                data: { moduleName: 'Meetings' },
                canActivate: [AuthGuard],
            },
            {
                path: 'reporting',
                loadChildren: () =>
                    import('./reporting/reporting.module').then((m) => m.ReportingModule),
                data: { moduleName: 'Meetings' },
                canActivate: [AuthGuard],
            },
            {
                path: 'documents',
                loadChildren: () =>
                    import('./meetings/components/document-display/document-display.module').then(
                        (m) => m.DocumentDisplayModule,
                    ),
            },
            {
                path: 'workpackage',
                loadChildren: () =>
                    import('./meetings/components/work-package/work-package.module').then(
                        (m) => m.WorkPackageModule,
                    ),
            },
            {
                path: 'statements',
                loadChildren: () =>
                    import('./statements/statements.module').then((m) => m.StatementssModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'resolutions',
                loadChildren: () =>
                    import('./decisions/decisions.module').then((m) => m.DecisionsModule),
                data: { moduleName: 'Agendas' },
                canActivate: [AuthGuard],
            },
            {
                path: 'agendas',
                loadChildren: () => import('./agendas/agendas.module').then((m) => m.AgendasModule),
                data: { moduleName: 'Agendas' },
                canActivate: [AuthGuard],
            },
            {
                path: 'sessionPlanning',
                loadChildren: () =>
                    import('./session-planning/session-planning.module').then(
                        (m) => m.SessionPlanningModule,
                    ),
                data: { moduleName: 'Agendas' },
                canActivate: [AuthGuard],
            },
            {
                path: 'speakers',
                loadChildren: () =>
                    import('./speakers/speakers.module').then((m) => m.SpeakersModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'implementationMemo',
                loadChildren: () =>
                    import('./implementationMemo/implementationMemo.module').then(
                        (m) => m.ImplementationMemoModule,
                    ),
                data: { moduleName: 'Agendas' },
                canActivate: [AuthGuard],
            },
            {
                path: 'mandates',
                loadChildren: () =>
                    import('./mandates/mandates.module').then((m) => m.MandatesModule),
                data: { moduleName: 'Agendas' },
                canActivate: [AuthGuard],
            },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./administration/administration.module').then(
                        (m) => m.AdministrationModule,
                    ),
                canActivate: [AuthGuard],
            },
        ],
    },
];

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
