export const PAGES_MENU = [
    {
        path: '',
        children: [
            // {
            //   path: 'meetings',
            //   data: {
            //     menu: {
            //       title: 'iGAEAD+',
            //       icon: 'ion-android-home',
            //       selected: false,
            //       expanded: false,
            //       order: 0,
            //       active: true
            //     }
            //   }
            // },
            //Agendas
            {
                path: 'agendas',
                data: {
                    menu: {
                        title: 'Agendas',
                        icon: 'ion-ios-list',
                        pathMatch: 'prefix',
                        selected: false,
                        expanded: false,
                        order: 1000,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'agendaMasterList',
                        data: {
                            menu: {
                                title: 'Master agenda list',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'agendaBuildup',
                        data: {
                            menu: {
                                title: 'Agenda buildup',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'agendaLogs',
                        data: {
                            menu: {
                                title: 'Agenda logs',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: '200',
                        data: {
                            menu: {
                                title: '200',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'sessionLookup',
                        data: {
                            menu: {
                                title: 'Session lookup',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                ],
            },
            {
                path: 'sessionPlanning',
                data: {
                    menu: {
                        title: 'Session planning',
                        icon: 'ion-ios-list',
                        pathMatch: 'prefix',
                        selected: false,
                        expanded: false,
                        order: 1000,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'meetingsList',
                        data: {
                            menu: {
                                title: 'Meetings list',
                                icon: 'ion-ios-list',
                                active: true,
                            },
                        },
                    },
                ],
            },
            //Meeting proceedings
            {
                path: '',
                data: {
                    menu: {
                        title: 'Meeting proceedings',
                        icon: 'ion-ios-world',
                        pathMatch: 'prefix',
                        selected: false,
                        expanded: false,
                        order: 800,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'meetings',
                        data: {
                            menu: {
                                title: 'Meetings',
                                icon: 'ion-ios-people',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'workpackage',
                        data: {
                            menu: {
                                title: 'Work packages',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'research-display', 'home'],
                        data: {
                            menu: {
                                title: 'Research Display',
                                icon: 'ion-ios-paper',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'documents',
                        data: {
                            menu: {
                                title: 'Documents',
                                icon: ' ion-android-document',
                                active: true,
                            },
                        },
                    },
                    /* {
            path: 'report',
            data: {
              menu: {
                title: 'Report',
                icon: 'ion-filing',
                active: true
              },
            }
          }, */
                    /*  {
             path: 'RulesOfProcedure',
             data: {
               menu: {
                 title: 'Rules of Procedure',
                 icon: 'ion-android-people',
                 active: true
               },
             },
           }, */
                    /* {
            path: 'PGAProcesses',
            data: {
              menu: {
                title: 'PGA Processes',
                icon: 'ion-android-people',
                active: true
              },
            },
          }, */
                ],
            },
            // mandates
            /*
      {
        path: "mandates",
        data: {
          menu: {
            title: "Mandates",
            icon: "ion-checkmark-circled",
            pathMatch: "prefix",
            selected: false,
            expanded: false,
            active: true,
            order: 1000,
          },
        },
        children: [],
      },
      {
        path: "implementationMemo",
        data: {
          menu: {
            title: "Implementation Memo",
            icon: "ion-android-list",
            active: true,
          },
        },
      },
      */
            {
                path: 'mandates',
                data: {
                    menu: {
                        title: 'Mandates',
                        icon: 'ion-checkmark-circled',
                        pathMatch: 'prefix',
                        selected: false,
                        expanded: false,
                        order: 1000,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'inbox',
                        data: {
                            menu: {
                                title: 'Mandate inbox',
                                icon: 'ion-archive',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'allTags',
                        data: {
                            menu: {
                                title: 'List of mandate tags',
                                icon: 'ion-ios-pricetags',
                                active: true,
                            },
                        },
                    },

                    {
                        path: 'implementationMemo',
                        data: {
                            menu: {
                                title: 'Implementation memo',
                                icon: 'ion-clipboard',
                                active: true,
                            },
                        },
                    },

                    /* {
            path: 'agendaslist',
            data: {
              menu: {
                title: 'Agenda Tags',
                icon: 'ion-android-list',
                active: true,
              },
            },
          },
          {
            path: 'reportlist',
            data: {
              menu: {
                title: 'Documentation Tags',
                icon: 'ion-android-list',
                active: true,
              },
            },
          },
          {
            path: 'meetingRequirementlist',
            data: {
              menu: {
                title: 'Meeting Requirements Tags',
                icon: 'ion-android-list',
                active: true,
              },
            },
          },
          {
            path: 'substantiveMandate',
            data: {
              menu: {
                title: 'Substantive Mandate Tags',
                icon: 'ion-android-list',
                active: true,
              },
            },
          },
          {
            path: 'opgaMandateslist',
            data: {
              menu: {
                title: 'OPGA Mandates Tags',
                icon: 'ion-android-list',
                active: true,
              },
            },
          } */
                ],
            },
            //Reporting
            {
                path: '',
                data: {
                    menu: {
                        title: 'Reporting',
                        icon: 'ion-ios-printer',
                        selected: false,
                        expanded: false,
                        order: 0,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'report',
                        data: {
                            menu: {
                                title: 'Reporting',
                                icon: 'ion-ios-printer',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'ProcedurePath'],
                        data: {
                            menu: {
                                title: 'Procedure Paths',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'MeetingListReport'],
                        data: {
                            menu: {
                                title: 'Meeting List',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'PublicEndpoint'],
                        data: {
                            menu: {
                                title: 'PublicEndpoint',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['reporting', 'template-logs'],
                        data: {
                            menu: {
                                title: 'Template logs',
                                icon: 'ion-grid',
                                active: true,
                            },
                        },
                    },
                ],
            },
            //Administration
            {
                path: 'administration',
                data: {
                    menu: {
                        title: 'Administration',
                        icon: 'ion-ios-settings',
                        selected: false,
                        expanded: false,
                        order: 1400,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'lookups',
                        data: {
                            menu: {
                                title: 'Lookups',
                                icon: 'ion-ios-glasses',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'agendalookup',
                        data: {
                            menu: {
                                title: 'Agenda lookup',
                                icon: 'ion-ios-glasses',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'access',
                        data: {
                            menu: {
                                title: 'Access management',
                                icon: 'ion-key',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'technicalsupport-rediscache',
                        data: {
                            menu: {
                                title: 'Cache',
                                icon: 'ion-android-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'roles',
                        data: {
                            menu: {
                                title: 'User profile',
                                icon: 'ion-person',
                                active: true,
                            },
                        },
                    },
                ],
            },

            /* {
        path: 'resolutions',
        data: {
          menu: {
            title: 'Resolutions and Decisions',
            icon: 'ion-ionic',
            pathMatch: 'prefix',
            selected: false,
            expanded: false,
            order: 1000,
          },
        },

      }, */

            /* {
        path: 'implementationMemo',
        data: {
          menu: {
            title: 'Implementation Memo',
            icon: 'ion-android-list',
            active: true,
          },
        },
      }, */

            /*  {
         path: 'statements',
         data: {
           menu: {
             title: 'Statements',
             icon: 'ion-android-list',
             selected: false,
             expanded: false,
             order: 900,
             active: true
           },
         },
       }, */
        ],
    },
];

export const PAGES_MENU_PROD = [
    {
        path: '',
        children: [
            //Meeting proceedings
            {
                path: '',
                data: {
                    menu: {
                        title: 'Meeting proceedings',
                        icon: 'ion-ios-world',
                        pathMatch: 'prefix',
                        selected: false,
                        expanded: false,
                        order: 800,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'meetings',
                        data: {
                            menu: {
                                title: 'Meetings',
                                icon: 'ion-ios-people',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'research-display'],
                        data: {
                            menu: {
                                title: 'Research Display',
                                icon: 'ion-ios-paper',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'workpackage',
                        data: {
                            menu: {
                                title: 'Work packages',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'documents',
                        data: {
                            menu: {
                                title: 'Documents',
                                icon: ' ion-android-document',
                                active: true,
                            },
                        },
                    },
                ],
            },

            //Reporting
            {
                path: '',
                data: {
                    menu: {
                        title: 'Reporting',
                        icon: 'ion-ios-printer',
                        selected: false,
                        expanded: false,
                        order: 0,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'report',
                        data: {
                            menu: {
                                title: 'Reporting',
                                icon: 'ion-ios-printer',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'ProcedurePath'],
                        data: {
                            menu: {
                                title: 'Procedure Paths',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'MeetingListReport'],
                        data: {
                            menu: {
                                title: 'Meeting List',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['meetings', 'PublicEndpoint'],
                        data: {
                            menu: {
                                title: 'PublicEndpoint',
                                icon: 'ion-cube',
                                active: true,
                            },
                        },
                    },
                    {
                        path: ['reporting', 'template-logs'],
                        data: {
                            menu: {
                                title: 'Template logs',
                                icon: 'ion-grid',
                                active: true,
                            },
                        },
                    },
                ],
            },
            //Administration
            {
                path: 'administration',
                data: {
                    menu: {
                        title: 'Administration',
                        icon: 'ion-ios-settings',
                        selected: false,
                        expanded: false,
                        order: 1400,
                        active: true,
                    },
                },
                children: [
                    {
                        path: 'lookups',
                        data: {
                            menu: {
                                title: 'Lookups',
                                icon: 'ion-ios-glasses',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'agendalookup',
                        data: {
                            menu: {
                                title: 'Agenda lookup',
                                icon: 'ion-ios-glasses',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'access',
                        data: {
                            menu: {
                                title: 'Access management',
                                icon: 'ion-key',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'technicalsupport-rediscache',
                        data: {
                            menu: {
                                title: 'Cache',
                                icon: 'ion-android-list',
                                active: true,
                            },
                        },
                    },
                    {
                        path: 'roles',
                        data: {
                            menu: {
                                title: 'User profile',
                                icon: 'ion-person',
                                active: true,
                            },
                        },
                    },
                ],
            },
        ],
    },
];
