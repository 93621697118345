import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';

@Injectable()
export class ApiCachingService {
    private headers = new HttpHeaders();
    map: Map<string, IServiceCachingMap> = new Map();
    intervalArr = [];
    C = 7 * 1000; // default clear interval.

    constructor(private http: HttpClient) {}

    getServiceData(method: string, url: string, q: any, b: any, c: number): Observable<any> {
        return from(
            new Promise(async (resolveParent) => {
                let mapValue = this.getMapValue(method, url, q, b, c);

                if (!mapValue.data) {
                    if (mapValue.inProgress) {
                        resolveParent(
                            new Promise((resolve) => {
                                mapValue.queue.push((resource) => resolve(resource));
                            }),
                        );
                    } else {
                        mapValue.inProgress = true;
                        try {
                            mapValue.data = await this.http[method]<any>(url, {
                                params: q,
                                ...(b && {body: b}),
                                headers: this.headers,
                            }).toPromise();
                        } catch (e) {
                            console.log(e);
                            mapValue.data = undefined;
                        }
                        mapValue.inProgress = false;
                        for (let callback of mapValue.queue) {
                            try {
                                callback(mapValue.data);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        mapValue.queue = [];
                        return resolveParent(mapValue.data);
                    }
                } else return resolveParent(mapValue.data);
            }),
        );
    }

    private getMapValue(
        method,
        url: string,
        queryParams: any,
        body: any,
        clearDataInInterval: number,
    ): IServiceCachingMap {
        let mapKey = this.getHash(
            `${method}+${url}+${JSON.stringify(queryParams)}+${JSON.stringify(body)}`,
        );

        let mapValue = this.map.get(mapKey);
        if (!mapValue) {
            mapValue = { inProgress: false, data: undefined, queue: [] };
            this.map.set(mapKey, mapValue);

            if (clearDataInInterval) {
                const interval = setInterval(() => {
                    if (mapValue.queue.length === 0) mapValue.data = undefined;
                }, clearDataInInterval);
                this.intervalArr.push(interval);
            }
        }
        return mapValue;
    }

    getHash(str: string): string {
        let hash = 0,
            i,
            chr;
        if (str.length === 0) return hash.toString();
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0;
        }
        return hash.toString();
    }

    logCache() {
        console.log(this.map);
    }

    clearCache() {
        this.map.clear();
        for (const item of this.intervalArr) clearInterval(item);
    }
}

interface IServiceCachingMap {
    data: any;
    inProgress: boolean;
    queue: any[];
}
