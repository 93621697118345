import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { CognitoUtil } from './cognito.service';
import { Router } from '@angular/router';
import { UserLoginService } from './user-login.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    private jwt: string;

    constructor(
        private cognitoUtil: CognitoUtil,
        private userLoginService: UserLoginService,
        private router: Router,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.cognitoUtil.getCurrentUser()) {
            this.cognitoUtil.getCurrentUser().getSession((err, session) => {
                if (session != undefined && session != null) {
                    this.jwt = session.getIdToken().getJwtToken();
                }
            });
        }

        if (this.jwt && !req.url.includes(environment.reportingServer)) {
            req = req.clone({ headers: req.headers.append('Authorization', this.jwt) });
        }

        return next.handle(req);
    }
}
