import { Component, OnInit } from '@angular/core';
import { CognitoUtil } from 'app/shared/_services/cognito.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import * as jsreport from 'jsreport-browser-client-dist';
import { AccessService } from '../access/services/access.service';
import { LookupService } from '../administration/services/lookup.service';

@Component({
    selector: 'app-report-generation',
    templateUrl: './report-generation.component.html',
    styleUrls: ['./report-generation.component.scss'],
})
export class ReportGenerationComponent implements OnInit {
    json = {
        MT_body: 'mtbody',

        MT_type: '',
        MT_session: '',
        MT_number: '1',
        MT_dateTimeScheduleStart: '',
        MT_dateTimeScheduleEnd: '',
        MT_presidingOfficer: '',
        MT_files: '',
        MT_commentary: '',
        MT_segment: [
            {
                MTS_segmentTitle: '',
                MTS_workPackageg: '',
                MTS_agendaItem: [],
                MTS_seqNo: 1,
            },
        ],

        procedureStep: [
            {
                PS_title: '',

                PS_type: '',

                PS_selectWorkPackage: [],

                PS_selectDocumentFromWorkPackage: [],

                PS_selectSingleEntity: '',

                PS_linkListOfSpeakers: '',

                PS_selectAgendaFromWorkPackage: [],

                PS_voting: '',

                PS_InFavor: '',

                PS_against: '',

                PS_abstention: '',

                PS_outcome: '',

                PS_linkToEvote: '',

                PS_recordDecisionNumber: '',

                PS_recordResolutionNumber: '',

                PS_agendaStatus: '',

                PS_meetingTime: '',

                seqNo: '',
            },
        ],
    };
    grouped = 'no';
    bybody = false;
    bysession = false;
    byid = false;
    body = '';
    id = '';
    session = '';
    bodyValues: any = [];
    sessionValues: any = [];
    idValues: any = [];
    templates: any = [];
    dataFilters: any = [];
    filter: any = '';
    recipes = [
        'docx',
        'chrome-pdf',
        'chrome-image',
        'html-with-browser-client',
        'pptx',
        'text',
        'static-pdf',
        'html-to-xlsx',
        'xlsx',
        'html',
    ];
    recipe = 'chrome-pdf';
    template = 'test-main';
    datasource = '';
    dataReporties: any = [];
    configurations: any = [];
    config = '';
    jwt: any = '';
    meetingData: any = [];
    loading = true;
    fullSessionItems;
    isSession = true;
    isConfiguration = true;

    constructor(
        public accessService: AccessService,
        public lookupService: LookupService,
        private cognitoUtil: CognitoUtil,
    ) {}

    sortData(data, key?) {
        if (key) return data.sort((a, b) => (a[key] > b[key] ? 1 : -1));
        else return data.sort((a, b) => (a > b ? 1 : -1));
    }
    async ngOnInit() {
        if (this.cognitoUtil.getCurrentUser()) {
            this.cognitoUtil.getCurrentUser().getSession((err, session) => {
                if (session != undefined && session != null) {
                    this.jwt = session.getIdToken().getJwtToken();
                }
            });
        }

        this.lookupService.getReporties().subscribe((data: any) => {
            data = data.filter((item: any) => !item.AG_Archived);
            data = data.map((item: any, i: any) => {
                return {
                    ...item,
                    name: item.RP_Name,
                };
            });
            data = this.sortData(data, 'name');
            this.configurations = data;
        });

        this.lookupService.getLookupData('Bodies').subscribe((res) => {
            let tempa = ([] = res as any);
            this.bodyValues = tempa.sort((a, b) => {
                if (a.LKP_Value < b.LKP_Value) {
                    return -1;
                }
                if (a.LKP_Value > b.LKP_Value) {
                    return 1;
                }
                return 0;
            });
        });

        this.lookupService.getLookupData('Sessions').subscribe((res) => {
            let tempa = ([] = res as any);
            this.fullSessionItems = tempa.sort((a, b) => {
                return a.LKP_Order - b.LKP_Order;
            });
            //this.sessionValues = this.fullSessionItems;
            this.processSessionShortVal();
        });

        this.accessService.getTemplates().subscribe((res: any) => {
            this.templates = res['value'];
        });

        this.dataFilters = [];
    }
    changeBody() {
        this.isSession = false;
        this.sessionValues = this.fullSessionItems.filter((session) =>
            session.LKP_Body.includes(this.body),
        );
        this.changeConfiguration();
    }
    changeSession() {
        this.isConfiguration = false;
        this.changeConfiguration();
    }

    processSessionShortVal() {
        for (let i = 0; this.fullSessionItems.length > i; i++) {
            this.fullSessionItems[i]['LKP_ValueShort'] = this.fullSessionItems[i]['LKP_ValueShort']
                .replace('th', '')
                .replace('st', '')
                .replace('rd', '');
        }
    }

    getInitialData() {
        let query = `?env=${environment.branch.toLowerCase()}&body=${this.body}&session=${
            this.session
        }`;
        let url =
            this.datasource == 'meeting'
                ? `/api/meetings/getmtconfsfiltered`
                : '/api/documents/getdocumentsfiltered';
        this.accessService.reportsData(url + query).subscribe(
            (res: any) => {
                this.meetingData = res;
                this.loading = false;
                this.changedDataSource();
            },
            (err) => {
                console.log('ërror', err);
                this.loading = false;
            },
        );
    }

    changeConfiguration() {
        if (this.config == '') return;

        let reportData = this.configurations[this.config];
        if (reportData) {
            console.log({ reportData });
            this.datasource = reportData['RP_DataSource'].toLowerCase();
            this.recipe = reportData['RP_Recipe'];
            this.template = reportData['RP_Template'];
            //this.bodyValues = [];
            //this.sessionValues = [];
            this.idValues = [];
            //this.session = "";
            //this.body = "";
            this.id = '';
            this.dataFilters = [];
            this.filter = '';
            this.loading = true;
            this.getInitialData();
        }
    }

    changedDataSource() {
        //this.bodyValues = [];
        //this.sessionValues = [];
        this.idValues = [];
        this.dataFilters = [];

        let query = `?env=${environment.branch.toLowerCase()}`;
        if (this.body) {
            query += '&body=' + this.body;
        }
        if (this.session) {
            query += '&session=' + this.session;
        }
        if (this.id) {
            query += '&id=' + this.id;
        }
        if (this.datasource == 'meeting') {
            this.meetingData.forEach((element: any) => {
                if (this.bodyValues.indexOf(element['MT_body']) == -1) {
                    //this.bodyValues.push(element["MT_body"]);
                    if (this.body) {
                        // if (this.sessionValues.indexOf(element["MT_session"]) == -1 && element["MT_body"] == this.body) {
                        //   this.sessionValues.push(element["MT_session"]);
                        // }
                        if (this.session) {
                            if (
                                element['MT_body'] == this.body &&
                                element['MT_session'] == this.session
                            ) {
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['MT_body'] +
                                        '-' +
                                        (element['MT_number'] ? element['MT_number'] : '') +
                                        '-' +
                                        element['MT_dateTimeCreate'],
                                });
                            }
                        } else {
                            if (element['MT_body'] == this.body)
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['MT_body'] +
                                        '-' +
                                        (element['MT_number'] ? element['MT_number'] : '') +
                                        '-' +
                                        element['MT_dateTimeCreate'],
                                });
                        }
                    }
                    if (!this.body) {
                        if (this.sessionValues.indexOf(element['MT_session']) == -1) {
                            this.sessionValues.push(element['MT_session']);
                        }
                        if (this.session) {
                            if (element['MT_session'] == this.session) {
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['MT_body'] +
                                        '-' +
                                        (element['MT_number'] ? element['MT_number'] : '') +
                                        '-' +
                                        element['MT_dateTimeCreate'],
                                });
                            }
                        } else {
                            this.idValues.push({
                                id: element['_id'],
                                value:
                                    '(' +
                                    element['_id'] +
                                    ')' +
                                    element['MT_body'] +
                                    '-' +
                                    (element['MT_number'] ? element['MT_number'] : '') +
                                    '-' +
                                    element['MT_dateTimeCreate'],
                            });
                        }
                    }
                }
            });
            //this.bodyValues = this.sortData(this.bodyValues);
            //this.sessionValues = this.sortData(this.sessionValues);

            let filteredData: any = [];
            this.meetingData.forEach((element: any) => {
                element['filter'] =
                    this.datasource === 'meeting'
                        ? element['MT_body'] +
                          ' - ' +
                          element['MT_session'] +
                          ' - ' +
                          (element['MT_number'] ? element['MT_number'] : ' ') +
                          ' - ' +
                          element['MT_dateTimeCreate']
                        : element['DD_Body'] + ' - ' + element['DD_documentType'];
                if (this.id) {
                    if (element['_id'] == this.id) {
                        filteredData.push(element);
                    }
                } else {
                    if (this.body) {
                        if (element['MT_body'] == this.body) {
                            if (this.session && element['MT_session'] == this.session) {
                                filteredData.push(element);
                            }
                        }
                    } else {
                        if (this.session && element['MT_session'] == this.session) {
                            filteredData.push(element);
                        } else {
                            filteredData.push(element);
                        }
                    }
                }
            });
            this.dataFilters = filteredData;
        } else if (this.datasource == 'document') {
            this.meetingData.forEach((element: any) => {
                if (this.bodyValues.indexOf(element['DD_Body']) == -1) {
                    this.bodyValues.push(element['DD_Body']);
                    if (this.body) {
                        if (
                            this.sessionValues.indexOf(element['DD_Session']) == -1 &&
                            element['DD_Body'] == this.body
                        ) {
                            this.sessionValues.push(element['DD_Session']);
                        }
                        if (this.session) {
                            if (
                                element['DD_Body'] == this.body &&
                                element['DD_Session'] == this.session
                            ) {
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['DD_Body'] +
                                        '-' +
                                        (element['DD_symbol1']
                                            ? element['DD_number1']
                                            : 'DD_symbol2') +
                                        '-' +
                                        element['DD_workingDate'],
                                });
                            }
                        } else {
                            if (element['DD_Body'] == this.body)
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['DD_Body'] +
                                        '-' +
                                        (element['DD_symbol1']
                                            ? element['DD_symbol1']
                                            : 'DD_symbol2') +
                                        '-' +
                                        element['DD_workingDate'],
                                });
                        }
                    }
                    if (!this.body) {
                        if (this.sessionValues.indexOf(element['DD_Session']) == -1) {
                            this.sessionValues.push(element['DD_Session']);
                        }
                        if (this.session) {
                            if (element['DD_Session'] == this.session) {
                                this.idValues.push({
                                    id: element['_id'],
                                    value:
                                        '(' +
                                        element['_id'] +
                                        ')' +
                                        element['DD_Body'] +
                                        '-' +
                                        (element['DD_symbol1']
                                            ? element['DD_symbol1']
                                            : 'DD_symbol2') +
                                        '-' +
                                        element['DD_workingDate'],
                                });
                            }
                        } else {
                            this.idValues.push({
                                id: element['_id'],
                                value:
                                    '(' +
                                    element['_id'] +
                                    ')' +
                                    element['DD_Body'] +
                                    '-' +
                                    (element['DD_symbol1'] ? element['DD_symbol1'] : 'DD_symbol2') +
                                    '-' +
                                    element['DD_workingDate'],
                            });
                        }
                    }
                }
            });
            this.bodyValues = this.sortData(this.bodyValues);
            this.sessionValues = this.sortData(this.sessionValues);

            let filteredData: any = [];
            this.meetingData.forEach((element: any) => {
                element['filter'] =
                    this.datasource === 'document'
                        ? element['DD_Body'] +
                          ' - ' +
                          element['DD_Session'] +
                          ' - ' +
                          (element['DD_symbol1'] ? element['DD_symbol1'] : 'DD_symbol2') +
                          ' - ' +
                          element['DD_workingDate']
                        : element['DD_Body'] + ' - ' + element['DD_documentType'];
                if (this.id) {
                    if (element['_id'] == this.id) {
                        filteredData.push(element);
                    }
                } else {
                    if (this.body) {
                        if (element['DD_Body'] == this.body) {
                            if (this.session && element['DD_Session'] == this.session) {
                                filteredData.push(element);
                            }
                        }
                    } else {
                        if (this.session && element['DD_Session'] == this.session) {
                            filteredData.push(element);
                        } else {
                            filteredData.push(element);
                        }
                    }
                }
            });
            this.dataFilters = filteredData;
        }
    }

    generateReport = async () => {
        this.loading = true;
        jsreport.serverUrl = environment.meetingsApi;
        jsreport.headers['authorization'] = this.jwt;
        jsreport.headers['env'] = environment.branch.toLowerCase();

        var request = {
            template: {
                name: this.template,
                recipe: this.recipe,
                engine: 'handlebars',
            },
            data: this.dataFilters[this.filter],
            options: { reports: { save: true } },
        };

        let extension = this.recipe;
        if (this.recipe == 'text') {
            extension = 'txt';
        }
        if (this.recipe == 'chrome-pdf') {
            extension = 'pdf';
        }
        if (this.recipe == 'html-with-browser-client') {
            extension = 'html';
        }

        //display report in the new tab
        let report = await jsreport.renderAsync(request);
        this.loading = false;
        report.download('myReport.' + extension);
        report.openInWindow();
        this.loading = false;
    };
}
