import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import { environment } from '././environments/environment';
import { AppModule } from './app/app.module';

// LicenseManager.setLicenseKey('ag-Grid_Evaluation_License_Key_Not_for_Production_1Devs13_December_2017__MTUxMzEyMzIwMDAwMA==d23971dbdbb4d82d11b1367e66a22ddf');
// LicenseManager.setLicenseKey('United_Nations__MultiApp_4Devs4_March_2020__MTU4MzI4MDAwMDAwMA==0a4e7753f06af598872417a31084c1ca');
// LicenseManager.setLicenseKey('CompanyName=United Nations,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=4,AssetReference=AG-010623,ExpiryDate=21_September_2021_[v2]_MTYzMjE3ODgwMDAwMA==af71b9317395032c3eecc11bc949ba09');
LicenseManager.setLicenseKey('CompanyName=United Nations,LicensedGroup=UN-DGACM-BAS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=4,AssetReference=AG-024180,ExpiryDate=11_January_2023_[v2]_MTY3MzM5NTIwMDAwMA==ab0b4a6f21641dc3d3bdc6b89adf3daa');

switch (environment.branch) {
    case 'DEV':
        document.head.insertAdjacentHTML('afterbegin', '<title>iGAEAD+ DEV</title>');
        break;
    case 'QAT':
        document.head.insertAdjacentHTML('afterbegin', '<title>iGAEAD+ QAT</title>');
        break;
    case 'UAT':
        document.head.insertAdjacentHTML('afterbegin', '<title>iGAEAD+ UAT</title>');
        break;
    case 'PROD':
        document.head.insertAdjacentHTML('afterbegin', '<title>iGAEAD+</title>');
        break;
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
