<div class="content-top clearfix">
  <h1 class="al-title" translate>{{ activePageTitleTop }}</h1>

  <ul class="breadcrumb al-breadcrumb">
    <li class="breadcrumb-item">
      <a routerLink="meetings" translate>{{'iGAEAD+'}}</a>
    </li>
    <li *ngIf="!newPageTitle" class="breadcrumb-item active">{{ activePageTitle }}</li>
    <li *ngIf="newPageTitle" class="breadcrumb-item active">{{ newPageTitle }}</li>
  </ul>
</div>
