export interface ITemplateMatchingDataPointLookup {
    _id?: string;
    TMD_type?: ESelectorType;
    TMD_name?: string; // EDataPointName
    TMD_value?: string[];
    TMD_dbField?: string[]; // Target can be array of objects and find that field values from that objects.
    TMD_tags?: string[];
    TMD_lookupName?: string;
    TMD_outputFormat?: string;
    TMD_outputFormatSelector?: string;
    TMD_supportSubTemplate?: boolean; // is this data point supports sub templates.
    TMD_outputFormatSubTemplateSelector?: string; // Code to select sub template based on conditions

    // UI fields
    TMD_typeUI?: string;
    TMD_dataPointUsedCount?: number;
    isPrimary?: boolean;
}

export enum ESelectorType {
    SELECT = '',
    LOOKUP = 'LOOKUP',
    STATIC_ITEMS = 'STATIC_ITEMS',
    OUTPUT_FORMAT = 'OUTPUT_FORMAT',
}

export enum EDataPointName {
    LOAD_ALL = 'Load all lookups',
}
