import { Injectable } from '@angular/core';

@Injectable()
export class AccessControlService {
    constructor() {}

    disableByAccess(accessType) {
        if (accessType !== 'edit' && accessType !== 'superUser' && accessType !== 'admin') {
            return {
                'pointer-events': 'none',
            };
        }
    }

    disableMeetingDisplayByAccess(accessType) {
        if (accessType !== 'edit' && accessType !== 'superUser') {
            return {
                'pointer-events': 'none',
            };
        }
    }

    removeByAccess(accessType) {
        if (accessType !== 'edit' && accessType !== 'superUser' && accessType !== 'admin') {
            return false;
        } else {
            return true;
        }
    }
    getApproveAccess(accessType) {
        if (accessType == 'superUser' || accessType == 'admin') {
            return true;
        } else {
            return false;
        }
    }
}
