import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';

// App is our top level component
import { App, LogoutComponent } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PagesModule } from './pages/pages.module';
import { AgGridModule } from 'ag-grid-angular';
import { UserLoginService } from './shared/_services/user-login.service';
import { ViewsService } from './pages/views/views.service';

import { CognitoUtil } from './shared/_services/cognito.service';

// import { ModalModule } from 'ngx-bootstrap/modal';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { ApiCachingService } from './shared/_services/api-caching.service';
import {
    AccessControlService,
    AlertService,
    AuthenticationService,
    CommonService,
    JWTInterceptor,
    NotifyService,
    UserService,
    SocketService,
} from './shared/_services';
import { AuthGuard } from './shared/_guards';
import {
    BaImageLoaderService,
    BaMenuService,
    BaThemePreloader,
    BaThemeSpinner,
} from './theme/services';
import { BaThemeConfig, BaThemeConfigProvider } from './theme';
import { NgIdleModule } from '@ng-idle/core';
import { DialogModule } from 'primeng/dialog';

// Application wide providers
const APP_PROVIDERS = [AppState, GlobalState];

export type StoreType = {
    state: InternalStateType;
    restoreInputValues: () => void;
    disposeOldHosts: () => void;
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [App],
    declarations: [App, LogoutComponent],
    imports: [
        // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        SimpleNotificationsModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        // NgaModule.forRoot(),
        NgaModule,
        NgbModule,
        PagesModule,
        routing,
        DialogModule,
        NgIdleModule.forRoot(),
        // ModalModule,
    ],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        // expose our Services and Providers into Angular's dependency injection
        APP_PROVIDERS,
        //customHttpProvider,
        AuthGuard,
        AlertService,
        ApiCachingService,
        AuthenticationService,
        UserService,
        CommonService,
        UserLoginService,
        CognitoUtil,
        ViewsService,
        NotifyService,
        BaImageLoaderService,
        BaMenuService,
        BaThemePreloader,
        BaThemeSpinner,
        BaThemeConfig,
        BaThemeConfigProvider,
        AccessControlService,
        SocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JWTInterceptor,
            multi: true,
        },
    ],
})
export class AppModule {
    constructor(public appState: AppState) {}
}
console.log('17.3.1');
