import { Component, ViewContainerRef, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

import { GlobalState } from './global.state';
import { AppState } from './app.service';
import { UserLoginService } from './shared/_services/user-login.service';
import { UserService } from './shared/_services/user.service';
import {
    ChallengeParameters,
    CognitoCallback,
    LoggedInCallback,
} from './shared/_services/cognito.service';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './theme/services';
import { BaThemeConfig } from './theme/theme.config';
import { layoutPaths } from './theme/theme.constants';

import { environment } from './../environments/environment';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

declare const AWSCognito: any;

// logout component
@Component({
    selector: 'logout',
    template: '',
})
export class LogoutComponent implements LoggedInCallback {
    model: any = {};
    errorMessage: string;
    mfaStep = false;
    mfaData = {
        destination: '',
        callback: null,
    };
    returnUrl: string;
    auth: any;

    constructor(
        public router: Router,
        private appState: AppState,
        public route: ActivatedRoute,
        public userService: UserLoginService,
    ) {
        this.userService.isAuthenticated(this);
    }

    isLoggedIn(message: string, auth: any) {
        auth.signOut();
    }

    initCognito() {
        var authData: any = {
            ClientId: environment.clientId, // Your client id here
            AppWebDomain: environment.appWebDomain,
            TokenScopesArray: [
                'profile',
                'email',
                'openid',
                'aws.cognito.signin.user.admin',
                'phone',
            ],
            RedirectUriSignIn: environment.callbackURL,
            RedirectUriSignOut: environment.signoutURL,
            IdentityProvider: 'unitelogin',
            UserPoolId: environment.userPoolId,
            AdvancedSecurityDataCollectionFlag: 'False',
        };

        var auth = new CognitoAuth(authData);

        auth.userhandler = {
            onSuccess: (result) => {
                this.router.navigate([this.returnUrl]);
                window.location.reload();
            },
            onFailure: function (err) {
                this.errorMessage = err;
            },
        };
        //auth.useCodeGrantFlow();
        return auth;
    }
}

/* 
app component
 */

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class App implements OnInit {
    environment: any = environment;
    isMenuCollapsed: boolean = false;
    hideSignin: boolean = false;
    spDrawn: boolean = false;
    model: any = {};
    errorMessage: string;
    mfaStep = false;
    mfaData = {
        destination: '',
        callback: null,
    };
    auth: any;
    returnUrl: string;

    /** options for notify start */
    public options: any = {
        position: ['bottom', 'right'],
        timeOut: 3000,
    };
    environmentTag: string;
    /** options for notify end */

    // some fields to store our state so we can display it in the UI
    idleState = { visible: false, message: 'Not started.' };
    countdown?: number = null;
    lastPing?: Date = null;

    constructor(
        private _state: GlobalState,
        private _imageLoader: BaImageLoaderService,
        private _spinner: BaThemeSpinner,
        private viewContainerRef: ViewContainerRef,
        private themeConfig: BaThemeConfig,
        public router: Router,
        public route: ActivatedRoute,
        private userLoginService: UserLoginService,
        private userService: UserService,
        private appState: AppState,
        private idle: Idle,
        cd: ChangeDetectorRef,
    ) {
        // set idle parameters 840 + 60 = 900s = 15 min
        idle.setIdle(840); // how long can they be inactive before considered idle, in seconds if a user does not perform any interaction (such as clicking a button, moving the mouse, typing, etc.)
        idle.setTimeout(60); // how long can they be idle before considered timed out, in seconds
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

        // do something when the user becomes idle
        idle.onIdleStart.subscribe(() => {
            this.idleState = { visible: false, message: "You've becomes idle!" };
        });
        // do something when the user is no longer idle
        idle.onIdleEnd.subscribe(() => {
            this.idleState = { visible: false, message: 'No longer idle.' };
            console.log(`${this.idleState} ${new Date()}`);
            this.countdown = null;
            cd.detectChanges(); // how do i avoid this kludge?
        });
        // do something when the user has timed out
        idle.onTimeout.subscribe(() => {
            this.userLoginService.logout();
            this.idle.stop();
            this.idleState = { visible: false, message: 'Timed out!' };
        });
        // do something as the timeout countdown does its thing
        idle.onTimeoutWarning.subscribe((seconds) => {
            const countdown = seconds;
            this.idleState = {
                visible: true,
                message: 'You will log out in ' + countdown + ' seconds!',
            };
        });

        themeConfig.config();
        this._loadImages();
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
            this.hideSignin = true;
        });
    }

    reset() {
        // we'll call this method when we want to start/reset the idle process
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = { visible: false, message: 'Started.' };
        this.countdown = null;
        this.lastPing = null;
    }

    ngOnInit() {
        this.environmentTag = environment.branch.toLowerCase();
        //this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
        this.returnUrl = localStorage.getItem('returnUrl');
        if (location.href.indexOf('signin') > -1) this.hideSignin = true;
        this.userLoginService.isAuthenticated(this);
        // right when the component initializes, start reset state and start watching
        if (this.userLoginService.cognitoUtil.getCurrentUser()) {
            this.reset();
        } else {
            this.idle.stop();
        }
    }

    public ngAfterViewInit(): void {
        // hide spinner once all loaders are completed
        BaThemePreloader.load().then((values) => {
            this._spinner.hide();
        });
    }

    private _loadImages(): void {
        // register some loaders
        BaThemePreloader.registerLoader(this._imageLoader.load('assets/img/sky-bg.jpg'));
    }

    onLogin() {
        if (this.model.AD_username == null || this.model.AD_password == null) {
            this.errorMessage = 'All fields are required';
            return;
        }
        this.errorMessage = null;
        this.userLoginService.authenticate(this.model.AD_username, this.model.AD_password, this);
    }

    // Same as UniteID
    getAuthSession() {
        this.auth.getSession();
        localStorage.setItem('session', this.auth.getSession());
    }

    unitloginSignOut() {
        if (this.auth) this.auth.signOut();

        this.userLoginService.logout();
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) {
            //error
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
            if (this.errorMessage === 'User is not confirmed.') {
                console.log('redirecting');
                this.router.navigate(['/home/confirmRegistration', this.model.AD_username]);
            } else if (this.errorMessage === 'User needs to set password.') {
                this.hideSignin = true;
                console.log('redirecting to set new password');
                console.log(this.returnUrl);
                this.router.navigate(['/signin/newpassword']);
            }
        } else {
            //success
            //this.ddb.writeLogEntry("login");
            //this.router.navigate(['/securehome']);
            this.hideSignin = true;
            this.router.navigate([this.returnUrl]);
        }
    }

    callOneTime = (function () {
        var executed = false;
        return function () {
            if (!executed) {
                executed = true;
                return window.location.reload();
            }
        };
    })();

    isLoggedIn(token: string, auth: any) {
        if (this.userLoginService.cognitoUtil.getCurrentUser()) {
            console.log('logged in,1');
            this.hideSignin = true;
            let currentUserName = this.userLoginService.cognitoUtil.getCurrentUser()['username'];
            this.userService.userCreate({ USR_username: currentUserName }).subscribe(
                (res) => {
                    if (res['success']) {
                        // get current user's profile and assign current user's full name by the signed in user's username
                        this.userService.getProfile(currentUserName).subscribe((profileRes) => {
                            localStorage.removeItem('user');
                            localStorage.setItem('user', JSON.stringify(profileRes['result'][0]));
                        });
                    } else {
                        //this.router.navigate(['azurelogin']);
                        this.router.navigate(['azurelogin'], {
                            queryParams: { returnUrl: this.returnUrl },
                        });
                    }
                },
                (err) => {
                    if (err.status == 409 || err.status == 401) {
                        console.log('isLoggedIn' + err);
                        this.callOneTime();
                        localStorage.clear();
                        this.router.navigate(['azurelogin']);
                        window.location.reload();
                    }
                },
            );
            //  if(!auth.signInUserSession.isValid()){
            //   this.router.navigate(['']);
            //   auth.signOut();
            // }
            // else{
            //  this.router.navigate(['/dashboard/']);
            // }
            if (this.returnUrl) {
                location.replace(this.returnUrl);
                this.returnUrl = '';
            } else if (
                window.location.href.includes('?code=') &&
                window.location.href.includes('&state=')
            ) {
                location.replace('/');
            }
        } else {
            //this.router.navigate(['azurelogin'])
            this.router.navigate(['azurelogin'], {
                queryParams: { returnUrl: this.returnUrl },
            });
            console.log('not logged in, initiate cognito');
            // this.auth = auth;
            this.auth = this.initCognito();
            var curUrl = window.location.href;
            //  this.auth.signOut();
            this.auth.parseCognitoWebResponse(curUrl);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        return false; //necessary to prevent href navigation
    }

    // Same as UniteID
    initCognito() {
        var authData: any = {
            ClientId: environment.clientId, // Your client id here
            AppWebDomain: environment.appWebDomain,
            TokenScopesArray: [
                'profile',
                'email',
                'openid',
                'aws.cognito.signin.user.admin',
                'phone',
            ],
            RedirectUriSignIn: environment.callbackURL,
            RedirectUriSignOut: environment.signoutURL,
            IdentityProvider: environment.provider,
            UserPoolId: environment.userPoolId,
            AdvancedSecurityDataCollectionFlag: 'False',
        };

        var auth = new CognitoAuth(authData);
        auth.userhandler = {
            onSuccess: (result) => {
                let currentUserName = this.userLoginService.cognitoUtil
                    .getCurrentUser()
                    .getUsername();
                this.userService.userCreate({ USR_username: currentUserName }).subscribe((res) => {
                    if (res['success']) {
                        // get current user's profile and assign current user's full name by the signed in user's username
                        this.userService.getProfile(currentUserName).subscribe((profileRes) => {
                            localStorage.removeItem('user');
                            localStorage.setItem('user', JSON.stringify(profileRes['result'][0]));
                            window.location.reload();
                        });
                    } else {
                        //this.router.navigate(['azurelogin'])
                        this.router.navigate(['azurelogin'], {
                            queryParams: { returnUrl: this.returnUrl },
                        });
                    }
                });
            },
            onFailure: function (err) {
                this.errorMessage = err;
            },
        };
        auth.useCodeGrantFlow();
        return auth;
    }

    public drawRotatingSymbol() {
        const d3 = require('d3');
        const topojson = require('topojson');

        try {
            // draw a rotating globe
            this.spDrawn = true;
            const width = window.screen.width * 0.45,
                height = window.screen.width * 0.45,
                speed = -1e-2,
                start = Date.now();

            const sphere = { type: 'Sphere' };

            const projection = (<any>d3).geo
                .orthographic()
                .scale(width / 2.1)
                .translate([width / 2, height / 2])
                .precision(0.5);

            const graticule = (<any>d3).geo.graticule();

            const canvas = d3
                .select('.auth-bg')
                .append('canvas')
                .attr('width', width)
                .attr('height', height);

            const context = canvas.node().getContext('2d');

            const path = (<any>d3).geo.path().projection(projection).context(context);

            d3.json(
                'https://s3-us-west-2.amazonaws.com/s.cdpn.io/95802/world-110m.json',
                (error, topo) => {
                    if (error) throw error;

                    const land = topojson.feature(topo, topo.objects.land),
                        grid = graticule();

                    d3.timer(function () {
                        context.clearRect(0, 0, width, height);

                        projection.rotate([speed * (start - Date.now()), -15]).clipAngle(90);

                        context.beginPath();
                        path(sphere);
                        context.lineWidth = 0;
                        context.strokeStyle = '#cbcbcb';
                        context.stroke();
                        context.fillStyle = '#efefef';
                        context.fill();

                        projection.clipAngle(180);

                        context.beginPath();
                        path(land);
                        context.fillStyle = '#99bdd8';
                        context.fill();

                        context.beginPath();
                        path(grid);
                        context.lineWidth = 0.5;
                        context.strokeStyle = 'rgba(119,119,119,0)';
                        context.stroke();

                        projection.clipAngle(90);

                        context.beginPath();
                        path(land);
                        context.fillStyle = '#2688d3';
                        context.fill();
                        context.lineWidth = 0;
                        context.strokeStyle = 'transparent';
                        context.stroke();
                    });
                },
            );

            d3.select(self.frameElement).style('height', height + 'px');
        } catch (err) {
            console.log(err);
        }
    }
}
