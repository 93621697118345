export enum EAccessType {
    noAccess = 'noAccess',
    read = 'read',
    edit = 'edit',
    superUser = 'superUser',
    admin = 'admin', // admin is greater than superUser.
}

export enum EAccessTypePriority {
    noAccess = 1,
    read = 2,
    edit = 3,
    superUser = 4,
    admin = 5,
}
