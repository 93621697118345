import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
// import { DynamoDBService } from "./ddb.service";
import { CognitoCallback, CognitoUtil, LoggedInCallback } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoAuth } from 'amazon-cognito-auth-js/dist/amazon-cognito-auth';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class UserLoginService {
    private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {
        console.log('In authenticateUser onSuccess callback');

        AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(
            session.getIdToken().getJwtToken(),
        );

        // So, when CognitoIdentity authenticates a user, it doesn't actually hand us the IdentityID,
        // used by many of our other handlers. This is handled by some sly underhanded calls to AWS Cognito
        // API's by the SDK itself, automatically when the first AWS SDK request is made that requires our
        // security credentials. The identity is then injected directly into the credentials object.
        // If the first SDK call we make wants to use our IdentityID, we have a
        // chicken and egg problem on our hands. We resolve this problem by "priming" the AWS SDK by calling a
        // very innocuous API call that forces this behavior.
        let clientParams: any = {};
        if (environment.sts_endpoint) {
            clientParams.endpoint = environment.sts_endpoint;
        }
        let sts = new STS(clientParams);
        sts.getCallerIdentity(function (err, data) {
            console.log('UserLoginService: Successfully set the AWS credentials');
            callback.cognitoCallback(null, session);
        });
    };

    private onLoginError = (callback: CognitoCallback, err) => {
        callback.cognitoCallback(err.message, null);
    };

    constructor(
        public cognitoUtil: CognitoUtil,
        public router: Router,
    ) {}

    authenticate(username: string, password: string, callback: CognitoCallback) {
        console.log('UserLoginService: starting the authentication');

        let authenticationData = {
            Username: username,
            Password: password,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool(),
        };

        console.log('UserLoginService: Params set...Authenticating the user');
        let cognitoUser = new CognitoUser(userData);
        console.log('UserLoginService: config is ' + AWS.config);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) =>
                callback.cognitoCallback(`User needs to set password.`, null),
            onSuccess: (result) => this.onLoginSuccess(callback, result),
            onFailure: (err) => this.onLoginError(callback, err),
            mfaRequired: (challengeName, challengeParameters) => {
                callback.handleMFAStep(
                    challengeName,
                    challengeParameters,
                    (confirmationCode: string) => {
                        cognitoUser.sendMFACode(confirmationCode, {
                            onSuccess: (result) => this.onLoginSuccess(callback, result),
                            onFailure: (err) => this.onLoginError(callback, err),
                        });
                    },
                );
            },
        });
    }

    forgotPassword(username: string, callback: CognitoCallback) {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool(),
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function () {},
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback(null, null);
            },
        });
    }

    confirmNewPassword(
        email: string,
        verificationCode: string,
        password: string,
        callback: CognitoCallback,
    ) {
        let userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool(),
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback(null, null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
        });
    }

    logout() {
        console.log('UserLoginService: Logging out');
        // this.ddb.writeLogEntry("logout");
        //this.cognitoUtil.getCurrentUser().signOut();
        if (this.cognitoUtil.getCurrentUser() === null) {
            localStorage.clear();
            this.router.navigate(['azurelogin']);
            //window.location.reload();
        } else {
            window.location.reload();
            localStorage.clear();
            this.router.navigate(['azurelogin']);
        }
    }

    async isAuthenticated(callback: LoggedInCallback) {
        if (callback == null) {
            throw new Error('UserLoginService: Callback in isAuthenticated() cannot be null');
        }

        let auth = this.initCognito();

        if (auth != null) {
            let session = auth.signInUserSession;
            if (session == null) {
                console.log("UserLoginService: Couldn't get the session: ");
                callback.isLoggedIn("UserLoginService: Couldn't get the session", false);
            } else {
                let token = auth.signInUserSession;
                localStorage.setItem('auth', auth);
                callback.isLoggedIn(token, true);
            }
        }

        const cognitoUser = this.cognitoUtil.getCurrentUser();

        if (cognitoUser != null) {
            let sessionObject;

            await cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                    callback.isLoggedIn(err, false);
                } else {
                    sessionObject = session;
                }
            });
            // Refresh session every 50 mins
            setInterval(async () => {
                await cognitoUser.refreshSession(
                    sessionObject.getRefreshToken(),
                    (refreshErr, refreshSession) => {
                        if (refreshErr) {
                            throw refreshErr;
                        } else {
                            AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(
                                refreshSession.getIdToken().getJwtToken(),
                            );

                            // this.userCreateService.userCreate(cognitoUser['username']).subscribe(res => {
                            //     if (res['success']) {
                            //         // get current user's profile and assign current user's full name by the signed in user's username);
                            //     } else {
                            //         console.log('Create User Failed');
                            //     }
                            // });
                        }
                    },
                );
            }, 900000);

            callback.isLoggedIn('', sessionObject?.isValid());
        } else {
            console.log("UserLoginService: can't retrieve the current user");
            callback.isLoggedIn("Can't retrieve the CurrentUser", false);
        }
    }

    initCognito() {
        var authData = {
            ClientId: environment.clientId, // Your client id here
            AppWebDomain: environment.appWebDomain,
            TokenScopesArray: [
                'profile',
                'email',
                'openid',
                'aws.cognito.signin.user.admin',
                'phone',
            ],
            RedirectUriSignIn: environment.callbackURL,
            RedirectUriSignOut: environment.signoutURL,
            IdentityProvider: 'unitelogin',
            UserPoolId: environment.userPoolId,
            AdvancedSecurityDataCollectionFlag: 'False',
        };

        var auth = new CognitoAuth(authData);
        auth.userhandler = {
            onSuccess: (result) => {
                this.router.navigate(['/pages']);
            },
            onFailure: function (err) {
                this.errorMessage = err;
            },
        };
        //auth.useCodeGrantFlow();
        return auth;
    }
}
