<h3>Report Generation</h3>
<ba-card class="col-xlg-12 col-lg-12 col-md-12 col-sm-12 col-12">
  

  <div class="row">
    <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" style="margin-top: 10px;" >
      <label>Body:</label>
      <select (change)="changeBody()" name="body" [(ngModel)]="body" class="form-control">
        <option value=""> Select Body</option>
        <option *ngFor="let _body of bodyValues" [value]="_body.LKP_ValueShort">{{_body.LKP_Value}}</option>
      </select>

    </div>
    <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" style="margin-top: 10px;">
      <div class="form-group">
      <label>Session:</label>
      <select [disabled]="isSession" (change)="changeSession()"  name="session" [(ngModel)]="session" class="form-control">
        <option value=""> Select Session</option>
        <option *ngFor="let _session of sessionValues" [value]="_session.LKP_Value">{{_session.LKP_Value}}</option>
      </select>
    </div>
    </div>
    <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" style="margin-top: 10px;">
      <div class="form-group">
      <label>Select Configuration</label>
      <select [disabled]="isConfiguration" class="form-control" [(ngModel)]="config" (change)="changeConfiguration()">
        <option [value]="''">Select Configuration</option>
        <option [value]="i" *ngFor="let config of configurations;let i=index">{{config.name}}</option>
      </select>
    </div>
    </div>

    
      <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="datasource" style="margin-top: 10px;">
          <label>Report Template:</label>
          <select [disabled]="true" [(ngModel)]="template" class="form-control">
            <option *ngFor="let template of templates" [value]="template.name">{{template.name}}</option>
          </select>
      </div>
      <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="datasource" style="margin-top: 10px;">
          <label>Template Recipe:</label>
          <select [disabled]="true" [(ngModel)]="recipe" class="form-control">
            <option value="{{recip}}" *ngFor="let recip of recipes">{{recip}}</option>
          </select>
      </div>
    

    <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="config" style="margin-top: 10px;">
      <div class="form-group">
        <label>Data Source:</label>
        {{datasource}}
        <select [disabled]="true" (change)="changedDataSource()" [(ngModel)]="datasource" class="form-control">
          <option value=""> Select Data Source</option>
          <option value="meeting">Meeting</option>
          <option value="document">Document</option>
        </select>
      </div>
    </div>

    <div class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="datasource" style="margin-top: 10px;">
      <div class="form-group" *ngIf="datasource">
        <label>Grouped:</label>
        <select [(ngModel)]="grouped" class="form-control" (change)="changedDataSource()">
          <option value="no">No</option>
          <option value="yes">Yes</option>

        </select>
      </div>
    </div>
    

    <div style="margin-top: 10px;" class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="datasource">
      <label>{{datasource | titlecase}} id:</label>
      <select (change)="changedDataSource()" *ngIf="datasource" type="text" [(ngModel)]="id" class="form-control">
        <option value=""> Select ID</option>
        <option *ngFor="let id of idValues" [value]="id.id">{{id.value}}</option>
      </select>
    </div>
    <div style="margin-top: 10px;" class="col-xlg-6 col-lg-6 col-md-6 col-sm-6 col-6" *ngIf="datasource">
      <label>Data Filter:</label>
      <select [(ngModel)]="filter" class="form-control">
        <option value="">Select Data</option>
        <option value="{{i}}" *ngFor="let filterData of dataFilters; let i=index">{{filterData['filter']}}</option>
      </select>
    </div>
  </div>


  <button *ngIf="datasource" (click)="generateReport()" class="pull-right btn btn-success" [disabled]="loading">{{loading?'Loading ....':'Submit'}}</button>
  <br /><br /><br />
  <pre *ngIf="dataFilters['filter'] && dataFilters.filter['data']" style="background-color: #ccc;">
      {{ dataFilters[filter]?.data | json}}
  </pre>
  <pre *ngIf="dataFilters['filter'] && !dataFilters.filter['data']" style="background-color: #ccc;">
    {{ dataFilters[filter] | json}}
</pre>
</ba-card>