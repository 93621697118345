import { Component } from '@angular/core';

import { GlobalState } from '../../../global.state';
import { UserLoginService } from '../../../shared/_services/user-login.service';
import { environment } from '../../../../environments/environment';
@Component({
    selector: 'ba-page-top',
    templateUrl: './baPageTop.html',
    styleUrls: ['./baPageTop.scss'],
})
export class BaPageTop {
    public isScrolled: boolean = false;
    public isMenuCollapsed: boolean = false;
    public environment = environment;

    constructor(
        private _state: GlobalState,
        private userLoginService: UserLoginService,
    ) {
        this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
            this.isMenuCollapsed = isCollapsed;
        });
    }

    public toggleMenu() {
        this.isMenuCollapsed = !this.isMenuCollapsed;
        this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
        return false;
    }

    public scrolledChanged(isScrolled) {
        this.isScrolled = isScrolled;
    }
    isLoggedIn(message: string, auth: any) {
        auth.signOut();
    }

    signout() {
        this.userLoginService.logout();
        //this.userLoginService.isAuthenticated(this);
    }
}
