import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient) {}

    login(username: string, password: string) {
        return this.http
            .post(`${environment.adminApi}/users/authenticate`, {
                AD_username: username,
                AD_password: password,
            })
            .pipe(
                map((response: Response) => {
                    // login successful if there's a jwt token in the response
                    let user = (<any>response).json();
                    if (user && user.token) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                    }

                    return user;
                }),
            );
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}
