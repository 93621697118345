import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './app.component';

const routes: Routes = [
    { path: 'signin', redirectTo: 'signin' },
    { path: 'azurelogin', component: LogoutComponent },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, { useHash: true });
