import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { routing } from './pages.routing';
import { NgaModule } from '../theme/nga.module';
import { AppTranslationModule } from '../app.translation.module';
import { FileUploadModule } from 'ng2-file-upload';
import { Pages } from './pages.component';
import { Sign } from './sign.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReportComponent } from './meetings/components/report/report.component';
import { ReportGenerationComponent } from './report-generation/report-generation.component';
import { AccessService } from './access/services/access.service';
import { LookupService } from './administration/services/lookup.service';

@NgModule({
    imports: [
        CommonModule,
        AppTranslationModule,
        NgaModule,
        routing,
        FileUploadModule,
        FormsModule,
    ],
    declarations: [
        Pages,
        Sign,
        ReportGenerationComponent,
        // ReportComponent
    ],
    providers: [AccessService, LookupService],
})
export class PagesModule {}
