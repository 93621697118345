import { ChangeDetectorRef, Component } from '@angular/core';

import { GlobalState } from '../../../global.state';

@Component({
    selector: 'ba-content-top',
    styleUrls: ['./baContentTop.scss'],
    templateUrl: './baContentTop.html',
})
export class BaContentTop {
    public newPageTitle = '';
    public activePageTitle: string = '';
    public activePageTitleTop: string = '';

    constructor(
        public _state: GlobalState,
        public ref: ChangeDetectorRef,
    ) {
        this._state.subscribe('menu.activeLink', (activeLink) => {
            if (activeLink) {
                this.activePageTitle = activeLink.title;
                if (this.activePageTitle == 'Agendas') {
                    this.activePageTitleTop = '';
                }
            }
        });

        // this._state.breadcrumbTitleChange.subscribe((newTitle: string) => {
        //   this.newPageTitle = newTitle;
        // });
    }
}
