import { Component } from '@angular/core';
import { Routes } from '@angular/router';

import { BaMenuService } from '../theme';
import { PAGES_MENU, PAGES_MENU_PROD } from './pages.menu';
import { environment } from '../../environments/environment';

@Component({
    selector: 'pages',
    template: `
    <ba-sidebar></ba-sidebar>
    <ba-page-top></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer class="al-footer clearfix">
     
      <div class="al-footer-main clearfix">
        <div class="al-copy">&copy; <a href="http://un.org" target="_blank">United Nations</a> 2022</div>
       
      </div>
    </footer>
    <ba-back-top position="200"></ba-back-top>
    `,
})
export class Pages {
    constructor(private _menuService: BaMenuService) {}

    ngOnInit() {
        if (environment.branch === 'PROD')
            this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU_PROD);
        else this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
    }
}
