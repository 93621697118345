import { Injectable } from '@angular/core';
import { View } from '../_models/views';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CommonService {
    public headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    public apiUrl = `${environment.lookupsApi}`; // URL to web api
    errormessage: string = '';
    originalRowdata: any = [];
    replaceOnce = require('replace-once');
    rowDatabefore = [];
    decisionData = [];

    options;
    constructor(public http: HttpClient) {}

    userName = this.getCurrentUser() ? this.getCurrentUser().AD_username : '';

    getViews(view): Promise<View[]> {
        return this.http
            .get(`${this.apiUrl}/api/views?list=${view}&user=${this.userName}`)
            .toPromise()
            .then((response) => (<any>response).json() as View[])
            .catch(this.handleError);
    }

    create(view: View): Promise<View> {
        return this.http
            .post(`${this.apiUrl}/api/views`, JSON.stringify({ view }))
            .toPromise()
            .then((res) => (<any>res).json().data as View)
            .catch(this.handleError);
    }

    updateViews(body: any) {
        const options = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }),
        };
        return this.http
            .put(`${this.apiUrl}/api/views`, JSON.stringify(body), options)
            .toPromise()
            .then((res) => (<any>res).json())
            .catch(this.handleError);
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    public replaceText(gridOptions, splitSearchTextArray, searchText, replaceText) {
        const allRowData = [];
        let itemDict = {};

        // access to visible node
        gridOptions.api.forEachNodeAfterFilter((node) => {
            itemDict = {};
            for (var dataKey in node.data) {
                if (node.data.hasOwnProperty(dataKey)) {
                    if (node.data[dataKey]) {
                        if (splitSearchTextArray && splitSearchTextArray.length > 1) {
                            itemDict[dataKey] = node.data[dataKey];
                            var changedString = node.data[dataKey].toString();
                            for (let i = 0; i < splitSearchTextArray.length; i++) {
                                if (
                                    changedString
                                        .toLowerCase()
                                        .includes(splitSearchTextArray[i].toLowerCase())
                                ) {
                                    changedString = changedString.replace(
                                        new RegExp(splitSearchTextArray[i].toLowerCase(), 'gi'),
                                        replaceText,
                                    );
                                }
                            }
                            itemDict[dataKey] = changedString;
                        } else {
                            itemDict[dataKey] = node.data[dataKey]
                                .toString()
                                .replace(new RegExp(searchText, 'gi'), replaceText);
                        }
                    }
                }
            }
            allRowData.push(itemDict);
        });
        return allRowData;
    }

    public replaceParaText(gridOptions) {
        const allRowData = [];
        let itemDict = {};

        // access to visible node
        if (gridOptions && gridOptions.api) {
            gridOptions.api.forEachNode((node) => {
                itemDict = {};
                for (var dataKey in node.data) {
                    if (node.data.hasOwnProperty(dataKey)) {
                        if (node.data[dataKey]) {
                            itemDict[dataKey] = node.data[dataKey].toString().replace(/“/g, '');
                            itemDict[dataKey] = itemDict[dataKey].toString().replace(/\.,/g, '¶');
                            itemDict[dataKey] = itemDict[dataKey]
                                .toString()
                                .replace(new RegExp('�', 'gi'), '');
                        }
                    }
                }
                allRowData.push(itemDict);
            });
        }

        return allRowData;
    }

    public replaceTextAfterExport(gridOptions, searchText, replaceText) {
        const allRowData = [];
        let itemDict = {};

        // access to visible node
        gridOptions.api.forEachNode((node) => {
            itemDict = {};
            for (var dataKey in node.data) {
                if (node.data.hasOwnProperty(dataKey)) {
                    if (node.data[dataKey]) {
                        if (
                            node.data[dataKey].includes('<span>') ||
                            node.data[dataKey].includes('</span>')
                        ) {
                            node.data[dataKey] = node.data[dataKey].replace(
                                new RegExp(searchText, 'gi'),
                                replaceText,
                            );
                            node.data[dataKey] = node.data[dataKey].replace(
                                new RegExp('</span>', 'gi'),
                                replaceText,
                            );
                        }
                    }
                }
            }
            allRowData.push(node.data);
        });

        return allRowData;
    }

    public highlightTextData(gridOptions, searchTextArray, searchText) {
        //this.setPVData(originalRowData);
        const replaceText = '<span class="highlight">$&</span>';
        return this.replaceText(gridOptions, searchTextArray, searchText, replaceText);
    }

    //select the dates in between the start date and the end date
    public getRangeData(gridOptions, dateColumn, startdate, endDate) {
        const allRowData = [];

        // access to all nodes
        gridOptions.api.forEachNode((node) => {
            if (
                node.data !== undefined &&
                node.data[dateColumn] &&
                node.data[dateColumn] !== undefined
            ) {
                let date = new Date(node.data[dateColumn]);

                if (date >= new Date(startdate) && date < new Date(endDate)) {
                    allRowData.push(node.data);
                }
            }
        });
        return allRowData;
    }

    //format the current date in yyyy-mm-dd
    loadCurrentDate() {
        let curDateTime = new Date();
        return (
            curDateTime.getFullYear() +
            '-' +
            ('0' + (curDateTime.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + curDateTime.getDate()).slice(-2)
        );
    }

    //used for exporting Ag-Grid Data as csv file and can change it into excel.
    public exportCsv(columnDefs, gridOptions, moduleName, isHightLight) {
        let columnKeys = [];
        const fileName = moduleName + '.csv';

        if (moduleName.toLowerCase() !== 'statements') {
            columnDefs.map((column) => {
                columnKeys.push(column['field']);
            });
        } else {
            columnKeys = columnDefs;
        }

        var params = {
            skipHeader: false,
            skipFooters: true,
            skipGroups: true,
            allColumns: false,
            columnKeys: columnKeys,
            fileName: fileName,
        };

        if (isHightLight) {
            const searchText = '<span class="highlight">';
            const replaceText = '';
            const isHightLight = false;
            const allRowData = this.replaceTextAfterExport(gridOptions, searchText, replaceText);
            gridOptions.api.setRowData(allRowData);
        }

        //gridOptions.api.setRowData(allRowData);
        gridOptions.api.exportDataAsCsv(params);
    }

    public getCurrentEnvironment() {
        let environment = '';

        if (window.location.href.includes('iscadqa')) {
            environment = 'qa';
        } else if (window.location.href.includes('iscadplus')) {
            environment = 'prod';
        } else {
            environment = 'dev';
        }
        return environment;
    }

    signinErrorMessage(errormessage) {
        this.errormessage = errormessage;
    }

    getSigninErrorMessage() {
        return this.errormessage;
    }

    getCurrentTime() {
        const currentdate = new Date();
        const currentTime = `${currentdate.getFullYear()}-${
            currentdate.getMonth() + 1
        }-${currentdate.getDate()}T${currentdate.getHours()}:
      ${currentdate.getMinutes()}:
      ${currentdate.getSeconds()}`;
        return currentTime;
    }

    getPVData() {
        return this.originalRowdata;
    }

    //storing initial PV data to update when ever user updates search functionality
    setPVData(pvdata) {
        this.originalRowdata = pvdata;
    }

    handleDateColumn(param) {
        if (param == null) return '';
        const d = new Date(param);
        const date =
            this.pad(d.getDate(), 2) + '/' + this.pad(d.getMonth() + 1, 2) + '/' + d.getFullYear();
        console.log(date);
        // return this.pad(d.getDate(), 2) + '/' + this.pad(d.getMonth() + 1, 2) + '/' +
        //   d.getFullYear();
        return '18/01/2018';
        //return date;
    }

    handleDateSpecial(data, column) {
        let rowData = [];
        if (data !== undefined && data && data.length > 0) {
            data.map((row) => {
                if (row[column] && row[column].length !== 10) {
                    row['DC_date'] = row['DC_dateString'];
                }
                rowData.push(row);
            });
        }
        return rowData;
    }

    //adding '0' before the number if it'sa single digit
    pad(num, totalStringSize) {
        let asString = num + '';
        while (asString.length < totalStringSize) asString = '0' + asString;
        return asString;
    }

    setDecisionData(data) {
        this.decisionData = data;
    }

    getDecisionData() {
        return this.decisionData;
    }
}
