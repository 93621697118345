import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserLoginService } from './../_services/user-login.service';
import { NotifyService, UserService } from '../_services';

@Injectable()
export class AuthGuard {
    private loggedIn: boolean = false;
    private isAuthorized: boolean;

    constructor(
        private router: Router,
        private userLoginService: UserLoginService,
        private notifyService: NotifyService,
        private userService: UserService,
    ) {
        this.userLoginService.isAuthenticated(this);
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) this.loggedIn = true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.loggedIn) {
            if (route.data && route.data['moduleName']) {
                let userProfile = JSON.parse(localStorage.getItem('user'));

                if (!userProfile) {
                    localStorage.setItem('returnUrl', state.url);
                    this.router.navigate(['azurelogin'], { queryParams: { returnUrl: state.url } });
                    this.notifyService.addNotify(
                        {
                            title: 'Failed',
                            msg: 'Unauthorized access',
                            type: 'error',
                            animate: 'fromRight',
                            theClass: 'small-icon',
                            timeOut: 3000,
                        },
                        this,
                    );
                    return false;
                }

                let userAccess =
                    userProfile['USR_Access'] && userProfile['USR_Access'].length > 0
                        ? userProfile['USR_Access']
                        : [];
                let moduleAccess = userAccess.filter(
                    (i) => i.moduleName === route.data['moduleName'],
                );

                if (moduleAccess.length > 0 && moduleAccess[0]['accessType'] !== 'noAccess') {
                    localStorage.removeItem('UA_' + route.data['moduleName']);
                    if (
                        moduleAccess[0]['accessType'] === 'edit' ||
                        moduleAccess[0]['accessType'] === 'admin' ||
                        moduleAccess[0]['accessType'] === 'superUser'
                    ) {
                        localStorage.setItem(
                            'UA_' + route.data['moduleName'],
                            moduleAccess[0]['accessType'],
                        );
                    } else {
                        localStorage.setItem('UA_' + route.data['moduleName'], 'read');
                    }
                    this.isAuthorized = true;
                } else {
                    localStorage.removeItem('UA_' + route.data['moduleName']);
                    localStorage.setItem('UA_' + route.data['moduleName'], 'noAccess');
                    this.isAuthorized = false;
                }

                if (this.isAuthorized) {
                    this.userService
                        .createLog('Access Success', route.data['moduleName'], 'Authorized Access')
                        .subscribe(
                            (res) => {},
                            (err) => console.log(err),
                        );

                    return true;
                } else {
                    this.userService
                        .createLog('Access Error', route.data['moduleName'], 'unauthorized Access')
                        .subscribe(
                            (res) => {},
                            (err) => console.log(err),
                        );

                    this.notifyService.addNotify(
                        {
                            title: 'No Acess',
                            msg: 'You do not have sufficient access privileges to the page you were trying to open.',
                            type: 'error',
                            animate: 'fromRight',
                            theClass: 'small-icon',
                            timeOut: 3000,
                        },
                        this,
                    );
                    return false;
                }
            } else {
                return true;
            }
        } else {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['azurelogin'], { queryParams: { returnUrl: state.url } });
            this.notifyService.addNotify(
                {
                    title: 'Failed',
                    msg: 'Session timeout',
                    type: 'error',
                    animate: 'fromRight',
                    theClass: 'small-icon',
                    timeOut: 3000,
                },
                this,
            );
            return false;
        }
    }
}
